import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useEffect, useState } from 'react';

import { BsCoin } from 'react-icons/bs';
import { FaMedal } from 'react-icons/fa';

import { Placeholder } from 'components';
import { useAuth, useBasic } from 'contexts';
import { getAchievements, getAllChallenges } from 'services';

import './style.scss';

type RecentAchievementsProps = {
  className?: string;
  [key: string]: any;
};

export function RecentAchievements({ className, ...rest }: RecentAchievementsProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>();
  const { user } = useAuth();
  const { pointsCategories } = useBasic();

  const getPoint = (item: any) => {
    const point = pointsCategories?.find(x => x._id === item.item);

    if (!point) {
      return <>---</>;
    }

    return (
      <span className="img inline-flex items-center justify-center w-4 h-4">
        {!!point?.image ? (
          <>
            <img src={point.image} alt={point.category} className="block w-full h-full object-contain" />
          </>
        ) : (
          <>
            {!!point?._id?.match(/xp/gi) ? (
              <FaMedal className="block w-full h-full object-contain text-base text-purple" />
            ) : (
              <BsCoin className="block w-full h-full object-contain text-base text-purple" />
            )}
          </>
        )}
      </span>
    );
  };

  useEffect(() => {
    async function loadData() {
      setLoading(true);

      try {
        const challenges = await getAllChallenges();
        const result = await getAchievements({
          $match: {
            player: user?._id,
          },
        });
        const preparedResult = result.map(item => {
          const challenge = challenges.find(challenge => challenge._id === item.item);
          return { ...item, challenge: challenge };
        });
        setData(preparedResult);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    }

    if (user) {
      loadData();
    }
  }, [user]);

  if (loading) {
    return (
      <div className={`recent-achievements ${className || ''}`}>
        <div className="grid h-48 gap-4">
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
        </div>
      </div>
    );
  }

  return (
    <div className={`recent-achievements ${className || ''}`}>
      <h3 className="title text-lg mb-2 w-full block font-medium">Conquistas recentes</h3>

      <div className="">
        {(!data || !data.length) && (
          <h4 className="">
            <em>Você ainda não obteve nenhuma conquista.</em>
          </h4>
        )}

        {!!data && !!data.length && (
          <div className="scroll-wrapper overflow-y-auto h-64">
            {data.map((item, index) => {
              return (
                <div
                  key={item._id}
                  className="block lg:flex items-center justify-between py-3 lg:py-2 border-b border-gray-100 border-solid"
                >
                  <div className="text-black-light flex justify-between">
                    {item.type === 0 && item.total > 0 && (
                      <div className="flex flex-row items-center space-x-2">
                        <span>Você ganhou:</span> <b>{item.total}</b> {getPoint(item)}
                      </div>
                    )}
                    {item.type === 0 && item.total < 0 && (
                      <div className="flex flex-row items-center space-x-2 text-red-300">
                        <span>Você gastou:</span> <b>{Math.abs(item.total)}</b> {getPoint(item)}
                      </div>
                    )}
                    {item.type === 2 && (
                      <div className="flex flex-row items-center space-x-2">
                        <span>Trocou um novo item na loja</span>
                      </div>
                    )}
                    {item.type === 3 && (
                      <div className="flex flex-row items-center space-x-2">
                        <b className="text-blue-300 font-bold">Subiu de nível!</b>
                      </div>
                    )}
                    {item.type === 1 && (
                      <>
                        {item.challenge && item.challenge.challenge && (
                          <div>
                            <div className="font-bold text-yellow-500 leading-none">Completou {item.challenge.challenge}</div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="text-black-light text-xs lg:text-right text-gray-400">
                    {formatDistance(new Date(item.time), new Date(), { addSuffix: true, locale: ptBR })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useToast } from 'contexts';
import { updatePassword } from 'services';
import { useNavigate } from 'react-router-dom';
import PasswordChecklist from 'react-password-checklist';

type ResetPasswordFormInputs = {
  username: string;
  code: string;
  password: string;
  password_confirm: string;
};

export default function ResetPasswordForm({ username }: { username: string }) {
  const { toastify } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormInputs>();
  const [sending, setSending] = useState(false);
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');

  const onSubmit: SubmitHandler<ResetPasswordFormInputs> = async data => {
    const { password, password_confirm } = data;

    const validatePassword = (password: string, passwordConfirm: string) => {
      const minLength = /.{8,}/;
      const specialChar = /[!@#$%^&*(),.?":{}|<>]/;
      const hasNumber = /\d/;
      const hasUpperCase = /[A-Z]/;

      // Verifica todas as condições
      if (!minLength.test(password)) {
        return 'A senha deve ter no mínimo 8 caracteres.';
      }
      if (!specialChar.test(password)) {
        return 'A senha deve ter um caractere especial.';
      }
      if (!hasNumber.test(password)) {
        return 'A senha deve ter pelo menos um número.';
      }
      if (!hasUpperCase.test(password)) {
        return 'A senha deve ter pelo menos uma letra maiúscula.';
      }
      if (password !== passwordConfirm) {
        return 'As senhas digitadas não são iguais.';
      }

      return null;
    };

    // Valida a senha
    const passwordError = validatePassword(password, password_confirm);
    if (passwordError) {
      toastify({
        content: passwordError,
        type: 'error',
      });
      return;
    }

    setSending(true);
    try {
      const result = await updatePassword({ ...data, username });
      if (result) {
        toastify({
          content: 'Senha alterada com sucesso.',
          type: 'success',
        });
      } else {
        toastify({
          content: 'Erro ao atualizar senha, tente novamente mais tarde.',
          type: 'error',
        });
      }
    } catch (error) {
      toastify({
        content: 'Erro ao atualizar senha, tente novamente mais tarde.',
        type: 'error',
      });
    } finally {
      setSending(false);
      navigate('/login');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <ul>
        <li>
          <label htmlFor="code">Código de Recuperação</label>
          <input
            {...register('code', { required: 'Campo obrigatório.' })}
            id="code"
            name="code"
            type="text"
            autoComplete="false"
            placeholder="Digite o código de recuperação"
          />
          {errors.code && <span role="alert">{String(errors.code.message)}</span>}

          <label htmlFor="password">Nova Senha</label>
          <input
            {...register('password', { required: 'Campo obrigatório.' })}
            id="password"
            name="password"
            type="password"
            autoComplete="false"
            placeholder="Digite a nova senha"
            onChange={e => setPassword(e.target.value)}
          />
          {errors.password && <span role="alert">{String(errors.password.message)}</span>}

          <label htmlFor="password_confirm">Confirmação de Senha</label>
          <input
            {...register('password_confirm', { required: 'Campo obrigatório.' })}
            id="password_confirm"
            name="password_confirm"
            type="password"
            autoComplete="false"
            placeholder="Digite novamente a senha"
            onChange={e => setPasswordAgain(e.target.value)}
          />
          {errors.password_confirm && <span role="alert">{String(errors.password_confirm.message)}</span>}

          <div className="pt-2 pb-2">
            <PasswordChecklist
              rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
              minLength={8}
              value={password}
              valueAgain={passwordAgain}
              messages={{
                minLength: 'A senha deve ter no mínimo 8 caracteres.',
                specialChar: 'A senha deve ter um caractere especial.',
                number: 'A senha deve ter pelo menos um número.',
                capital: 'A senha deve ter pelo menos uma letra maiúscula.',
                match: 'As senhas digitadas devem ser iguais.',
              }}
            />
          </div>

          <button type="submit" disabled={sending} className="py-2 px-4">
            Redefinir Senha
          </button>
        </li>
      </ul>
    </form>
  );
}

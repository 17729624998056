import { useMemo, useState } from 'react';
import { FaLock, FaPlay } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { DetailContentInterface } from 'types';

import { AiFillFileText, AiFillVideoCamera } from 'react-icons/ai';
import { FaFileDownload } from 'react-icons/fa';
import { MdQuiz } from 'react-icons/md';
import { IoGameController, IoReturnDownForward } from 'react-icons/io5';
import { RiFolderFill, RiFolderLockFill, RiFolderOpenFill } from 'react-icons/ri';

import './style.scss';

import noImage from 'assets/images/no-image.jpg';

export type NextLessonsProps = {
  nextLessons: DetailContentInterface[];
  courseID: string;
  folderID: string;
  className?: string;
  [key: string]: any;
};

export function NextLessons({ nextLessons, courseID, folderID, className, ...rest }: NextLessonsProps) {
  const navigate = useNavigate();

  const getLessonPermalink = (courseID: string, folderID: string, contentID: string) => {
    return `/cursos/${courseID}/${folderID}/${contentID}`;
  };

  const entityIcon = (item: any) => {
    if (item.type === 'video') {
      return <AiFillVideoCamera />;
    } else if (item.type === 'text') {
      return <AiFillFileText />;
    } else if (item.type === 'doc') {
      return <FaFileDownload />;
    } else if (item.type === 'quiz') {
      return <MdQuiz />;
    } else if (!!item.type.match(/game_/gi)) {
      return <IoGameController />;
    } else {
      return <></>;
    }
  };

  return (
    <div className={`next-lessons ${className || ''}`}>
      <div className="list">
        <h4>Atividades</h4>

        <div>
          <ul className="lessons">
            {nextLessons.map(lesson => (
              <li
                className={`lesson-item ${lesson.active ? 'active' : 'inactive'} ${lesson.open ? 'unlocked' : 'locked'}`}
                key={lesson._id}
              >
                <button
                  type="button"
                  onClick={() =>
                    lesson && !lesson.active && lesson.open && getLessonPermalink(courseID, folderID, lesson._id).length
                      ? navigate(getLessonPermalink(courseID, folderID, lesson._id))
                      : null
                  }
                >
                  <span className={`img ${lesson.active ? 'active' : 'inactive'} ${lesson.open ? 'unlocked' : 'locked'}`}>
                    {lesson.data?.image?.length && <img src={lesson.data.image} alt={lesson.title} />}
                    {!lesson.active && lesson.open && entityIcon(lesson)}
                    {lesson.active && <FaPlay />}
                    {!lesson.open && <FaLock />}
                  </span>

                  <div className="meta">
                    <h6>{lesson.title}</h6>
                    <p>
                      {lesson.type === 'video' && 'Vídeo'}
                      {lesson.type === 'text' && 'Texto'}
                      {lesson.type === 'doc' && 'Documento'}
                      {lesson.type === 'quiz' && 'Quiz'}
                      {!!lesson.type.match(/game_/gi) && 'Minigame'}
                      {!!lesson.data?.duration ? ` - ${lesson.data?.duration} • ` : ' • '}

                      <span className={`${lesson.percent === 100 ? 'text-[#2bb788]' : ''}`}>{`${Math.round(
                        lesson.percent || 0,
                      )}% concluído`}</span>
                    </p>
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useAuth, useToast } from 'contexts';
import { changePassword, sendMessage } from 'services';
import PasswordChecklist from 'react-password-checklist';

import './style.scss';

type InputsForm = {
  old_password: string;
  new_password: string;
  new_password_confirm: string;
};

export default function EditProfilePage() {
  const { user } = useAuth();
  const { toastify } = useToast();
  const navigate = useNavigate();

  const [sending, setSending] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');

  const {
    register,
    handleSubmit: submit,
    watch,
    formState: { errors },
    reset,
  } = useForm<InputsForm>();

  // const form_subject = watch('subject', '');
  // const form_message = watch('message', '');

  const handleSubmit: SubmitHandler<InputsForm> = async data => {
    if (sending || !user) return;
    const { new_password, new_password_confirm } = data;

    const validatePassword = (password: string, passwordConfirm: string) => {
      const minLength = /.{8,}/;
      const specialChar = /[!@#$%^&*(),.?":{}|<>]/;
      const hasNumber = /\d/;
      const hasUpperCase = /[A-Z]/;

      // Verifica todas as condições
      if (!minLength.test(password)) {
        return 'A senha deve ter no mínimo 8 caracteres.';
      }
      if (!specialChar.test(password)) {
        return 'A senha deve ter um caractere especial.';
      }
      if (!hasNumber.test(password)) {
        return 'A senha deve ter pelo menos um número.';
      }
      if (!hasUpperCase.test(password)) {
        return 'A senha deve ter pelo menos uma letra maiúscula.';
      }
      if (password !== passwordConfirm) {
        return 'As senhas digitadas não são iguais.';
      }

      return null;
    };

    // Valida a senha
    const passwordError = validatePassword(new_password, new_password_confirm);
    if (passwordError) {
      toastify({
        content: passwordError,
        type: 'error',
      });
      return;
    }

    setSending(true);

    const payload = {
      _id: user._id,
      old_password: data.old_password,
      new_password: data.new_password,
    };

    changePassword(payload)
      .then(data => {
        toastify({
          content: 'Sua senha foi atualizada com sucesso!',
          type: 'success',
        });

        // navigate('../');
      })
      .catch(() => {
        toastify({
          content: 'Ocorreu um erro ao atualizar sua senha. Por favor, tente novamente.',
          type: 'error',
        });
      })
      .finally(() => setSending(false));
  };

  return (
    <section id="edit-profile">
      <h2 className="text-xl lg:text-3xl mb-5 lg:mb-8">Acesso e segurança</h2>

      <form action="" className="max-w-md" onSubmit={submit(handleSubmit)} noValidate>
        <ul className="grid grid-cols-1 gap-3">
          <li>
            <label htmlFor="subject">Digite a sua senha atual:</label>
            <span className="block relative">
              <input
                tabIndex={1}
                type="password"
                id="subject"
                aria-invalid={errors.old_password ? 'true' : 'false'}
                {...register('old_password', {
                  required: 'Campo obrigatório.',
                })}
                disabled={sending}
              />
              {/* <span className="count">{form_subject?.trim().length || 0}/60</span> */}
            </span>
            {errors.old_password && <span role="alert">{errors.old_password.message}</span>}
          </li>

          <li>
            <label htmlFor="subject">Digite a nova senha:</label>
            <span className="block relative">
              <input
                tabIndex={1}
                type="password"
                id="new_password"
                aria-invalid={errors.new_password ? 'true' : 'false'}
                {...register('new_password', {
                  required: 'Campo obrigatório.',
                })}
                disabled={sending}
                onChange={e => setPassword(e.target.value)}
              />
              {/* <span className="count">{form_subject?.trim().length || 0}/60</span> */}
            </span>
            {errors.new_password && <span role="alert">{errors.new_password.message}</span>}
          </li>

          <li>
            <label htmlFor="subject">Confirme a nova senha:</label>
            <span className="block relative">
              <input
                tabIndex={1}
                type="password"
                id="new_password_confirm"
                aria-invalid={errors.new_password_confirm ? 'true' : 'false'}
                {...register('new_password_confirm', {
                  required: 'Campo obrigatório.',
                })}
                disabled={sending}
                onChange={e => setPasswordAgain(e.target.value)}
              />
              {/* <span className="count">{form_subject?.trim().length || 0}/60</span> */}
            </span>
            {errors.new_password_confirm && <span role="alert">{errors.new_password_confirm.message}</span>}

            <div className="pt-2 pb-2">
              <PasswordChecklist
                rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
                minLength={8}
                value={password}
                valueAgain={passwordAgain}
                messages={{
                  minLength: 'A senha deve ter no mínimo 8 caracteres.',
                  specialChar: 'A senha deve ter um caractere especial.',
                  number: 'A senha deve ter pelo menos um número.',
                  capital: 'A senha deve ter pelo menos uma letra maiúscula.',
                  match: 'As senhas digitadas devem ser iguais.',
                }}
              />
            </div>
          </li>
        </ul>

        <button type="submit" disabled={sending} tabIndex={4}>
          Enviar
        </button>
      </form>
    </section>
  );
}

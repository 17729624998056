import { useEffect, useState } from 'react';

import { useAuth, useCourses } from 'contexts';
import { registerFolderLog } from 'services';
import { DetailContentInterface } from 'types';

type TextProps = {
  content: any;
  className?: string;
  [key: string]: any;
};

export function Html({ content, className, ...rest }: TextProps) {
  if (!content) return null;

  return (
    <div
      className={`text-component w-full relative bg-white ${className || ''}`}
      dangerouslySetInnerHTML={{ __html: (content.content as string) || '' }}
    />
  );
}

import { useAuth } from "contexts";
import { useEffect, useMemo, useState } from "react";

import { GameInterface } from "types/games";

import "./style.scss";
import { api } from "services";
import { getToken } from "utils";

export const GameFolder: any = {
	game_crossword: "crossword",
	game_wordsearch: "wordsearch",
	game_codex: "codex",
	game_enigma: "enigma",
	game_runner: "runner",
	game_detective: "detective",
	game_story: "story",
	game_embed: "embed",
	game_puzzle: "puzzle",
	game_memory: "memory",
};

export type GameProps = {
	id: string;
	contentId?: string;
	className?: string;
	[key: string]: any;
};

export function GameContainer({ id, contentId, className }: GameProps) {
	const { user } = useAuth();
	const [gameURL, setGameUrl] = useState<any>();
	const [height, setHeight] = useState<number>();

	const hasAspectRatio16vs9 = useMemo(() => {
		if (typeof id === "string" && !!id.match(/(game_story|phishing)/gi)) {
			return false;
		}

		return false;
	}, [id]);

	useEffect(() => {
		async function loadData() {
			if (!id) return;
			try {
				let service =
					!!(window as any).FUNIFIER_ENV?.service &&
					typeof (window as any).FUNIFIER_ENV?.service === "string"
						? (window as any).FUNIFIER_ENV?.service
						: process.env.REACT_APP_API_URL;

				service = service?.replace(/^(https?:\/\/)(.*)(\.funifier.*)$/gi, "$2");

				if (id === "embed") {
					const { data } = await api.post(`find/${id}`, {
						contentId: contentId,
					});

					if (data && data.length > 0) {
						console.log(data);
						if (data[0]?.url) {
							setGameUrl(
								`${
									data[0]?.url
								}?contentId=${contentId}&authorization=${getToken()}&service=${service}`,
							);
						}
					}
				} else {
					setGameUrl(
						`/game/${
							GameFolder[id] || id
						}/index.html?contentId=${contentId}&authorization=${getToken()}&service=${service}`,
					);
				}
			} catch (e) {}
		}

		loadData();

		console.log("GameContainer", id, contentId);
	}, [id, contentId]);

	useEffect(() => {
		function receiveMessage(message: any) {
			if (message.data && typeof message.data === "string") {
				if (!!message.data.match(/height/gi)) {
					let _height = Number(message.data.replace(/[^.\d]/gi, ""));

					if (!isNaN(_height)) {
						_height = Math.round(_height);

						if (_height > 0) {
							setHeight(_height);
						} else {
							setHeight(undefined);
						}
					}
				}
			}
		}

		window.addEventListener("message", receiveMessage);

		return () => window.removeEventListener("message", receiveMessage);
	}, []);

	if (!gameURL) return null;

	return (
		<div
			className={`game-container flex-1 h-full rounded-xl lg:rounded-3xl overflow-hidden`}
			style={
				{
					"--height": `${height ? `${height}px` : "auto"}`,
				} as React.CSSProperties
			}
		>
			<iframe src={gameURL} title="game iframe" className="w-full h-full" />
		</div>
	);
}

import { BiLock } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

// import { GameInterface } from "types/games";
import placeholder from 'assets/images/games/placeholder.jpg';

import './style.scss';
import { useEffect, useState } from 'react';
import { CgChevronRight } from 'react-icons/cg';
import { getGames } from 'services/games.service';
import { useBasic } from 'contexts';

export default function GamesPage() {
  const [gamesList, setGamesList] = useState<any[]>([]);
  const navigate = useNavigate();
  const { appearance } = useBasic();

  function handleClick(item: any) {
    if (item.type === 'section') {
      navigate(`./list/${item._id}`);
    } else {
      navigate(`./${item.type.replace('game_', '')}/${item._id}`);
    }
  }

  useEffect(() => {
    const loadData = async () => {
      const data = await getGames();
      setGamesList(data);
    };

    loadData();
  }, []);

  return (
    <>
      <h2 className="text-xl lg:text-3xl mb-10">Divirta-se e aprenda mais sobre {appearance?.title || 'a plataforma'}.</h2>

      <div className="flex flex-col gap-8">
        {gamesList.map(item => (
          <div key={item._id}>
            <h3 className="text-xl lg:text-3xl mb-4 font-bold">{item.title}</h3>
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
              {item.games.map((game: any) => (
                <div
                  key={game._id}
                  onClick={e => handleClick(game)}
                  className={`hover:ring-2 hover:cursor-pointer game-list-item flex flex-col lg:flex-row justify-center lg:justify-start items-center p-3 lg:p-0 shadow-xl rounded-xl overflow-hidden	
							`}
                >
                  <div className="mb-5 lg:mb-0 w-24 h-24  lg:w-32 lg:h-32 p-1 flex">
                    <img
                      src={game.image || game.extra.image || placeholder}
                      alt={game.title}
                      className="rounded-md lg:rounded-lg object-cover w-full h-full bg-gray-100"
                    />
                  </div>
                  <div className="space-y-2 px-5 lg:px-10 flex-1 text-center lg:text-left">
                    <h4 className="text-base lg:text-xl">{game.title}</h4>
                    <p className="text-gray-500 leading-tight">{game.extra.description}</p>
                  </div>
                  <div className="p-5 lg:p-8">
                    <div className="border rounded-full aspect-square p-2">
                      <CgChevronRight size={24} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

import { useEffect } from 'react';
import { useCourses, useAuth } from 'contexts';
import { BoxCourses } from 'components';
import { Placeholder } from 'components';
import { Navigate, useParams, useNavigate } from 'react-router-dom';

import './style.scss';

import { getCourses, getDetailContent } from 'services';

export default function CoursesPage() {
  const navigate = useNavigate();

  const { user } = useAuth();
  const { listCourses } = useCourses();
  const { profileUser } = useAuth();

  const loadCourses = async () => {
    if (user && (!user.challenges['startCourse'] || user.challenges['startCourse'] === 0)) {
      const courses = await getCourses(user?._id);

      if (courses) {
        courses.forEach(element => {
          const title = element.title
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLocaleLowerCase();
          if ((title === 'inicio' || title === 'start') && element.extra.showOnLogin) {
            getDetailContent(element._id).then(function (res) {
              const content = res || [];
              navigate(`/cursos/${element._id}/${element._id}/${content[0]._id}`);
            });
          }
        });
      }
    }
  };

  useEffect(() => {
    loadCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!Array.isArray(listCourses)) {
    return (
      <>
        <div className="flex flex-col space-y-6">
          <div className="flex flex-row space-x-3">
            <Placeholder className="w-10 h-10 rounded-full" />
            <Placeholder className="flex-1 h-10 rounded-full" />
          </div>

          <Placeholder className="h-64" />

          <div className="grid grid-cols-3 h-48 gap-6">
            <Placeholder />
            <Placeholder />
            <Placeholder />
          </div>
        </div>
      </>
    );
  }

  if (!listCourses.length) {
    return (
      <>
        <h2 className="block text-center p-6">Nenhum curso encontrado.</h2>
      </>
    );
  }

  return (
    <>
      <BoxCourses courses={listCourses} title="Cursos" classNameCards="flex-wrap" profiles={profileUser?.roles} />
    </>
  );
}

import { useState, useEffect, useRef, useCallback } from 'react';
import ReactPlayer from 'react-player/lazy';

import { useAuth, useCourses } from 'contexts';
import { DetailContentInterface } from 'types';
import { getFolderLog, registerFolderLog } from 'services';

import './style.scss';

type VideoProps = {
  content: DetailContentInterface;
  className?: string;
  [key: string]: any;
};

export function Video({ content, className, ...rest }: VideoProps) {
  const rpRef = useRef<any>();

  const { user } = useAuth();
  const { getFullProgress } = useCourses();

  const [loading, setLoading] = useState(true);
  const [log, setLog] = useState<any>();
  const [ready, setReady] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);

  useEffect(() => {
    setDone(false);
    setReady(false);
    setLog(undefined);
  }, [content]);

  useEffect(() => {
    if (!user) return;

    getFolderLog(user._id, content._id)
      .then(data => {
        if (data.length) {
          setLog(data[0]);
        }
      })
      .finally(() => setLoading(false));

  }, [content, user]);

  // useEffect(() => {
  //   if (log?.status === 'in_progress' && log?.extra?.played) {
  //     rpRef.current?.seekTo(log?.extra?.played, 'fraction');
  //   }
  // }, [log]);

  const handleProgress = useCallback((state: { playedSeconds: number; played: number; loadedSeconds: number; loaded: number }) => {
    if (!user || !ready) return;

    if ((!log || log?.status === 'in_progress') && !done) {
      if (state.played > 0.95) {
        registerFolderLog(user._id, content._id, 'done').finally(() => {
          getFullProgress();
        });

        setDone(true);
      } else {
        registerFolderLog(user._id, content._id, 'in_progress', { played: state.played });
      }
    }
  }, [content, done, getFullProgress, log, ready, user]);

  const handleReady = useCallback(() => {
    if (log?.status === 'in_progress' && log?.extra?.played) {
      rpRef.current?.seekTo(log?.extra?.played, 'fraction');
    }

    if (log?.status === 'done') {
      setDone(true);
    }

    setReady(true);
  }, [log]);

  if (!content || !content.data || loading || typeof content.data.url !== 'string') return null;

  return (
    <div className={`video-component ${className || ''}`}>
      <ReactPlayer
        ref={rpRef}
        url={content.data.url}
        className="player"
        playing
        controls
        width="100%"
        height="100%"
        onProgress={handleProgress}
        onReady={handleReady}
        config={{
          youtube: {
            playerVars: { showinfo: 0, controls: 1 },
          },
        }}
      />
    </div>
  );
}

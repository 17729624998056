import { useState, useEffect } from 'react';
import RequestRecoveryCode from './RequestRecoveryCode';
import ResetPasswordForm from './ResetPasswordForm';
import { useBasic } from 'contexts';
import { Link } from 'react-router-dom';

import './style.scss';

export default function RecoveryPasswordPage() {
  const [recoverySent, setRecoverySent] = useState(false);
  const [username, setUsername] = useState('');
  const { setCustomClass } = useBasic();

  useEffect(() => {
    setCustomClass('recovery');

    return () => setCustomClass('');
  }, [setCustomClass]);

  const handleSuccess = (username: string) => {
    setUsername(username);
    setRecoverySent(true);
  };

  return (
    <section id="recovery">
      <h2 className="text-xl lg:text-3xl mb-5 lg:mb-8"> Esqueceu a senha?</h2>

      {!recoverySent ? <RequestRecoveryCode onSuccess={handleSuccess} /> : <ResetPasswordForm username={username} />}
      <div className="text-center m-8">
        <Link to="/login" className="ml-1 text-blue-500">
          Voltar para Login
        </Link>
      </div>
    </section>
  );
}

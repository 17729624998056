import { api } from './api';

type SignInRequestData = {
  username: string;
  password: string;
  apiKey?: string;
  grant_type?: string;
};

const APIKEY =
  !!(window as any).FUNIFIER_ENV?.apikey && typeof (window as any).FUNIFIER_ENV?.apikey === 'string'
    ? (window as any).FUNIFIER_ENV?.apikey
    : process.env.REACT_APP_APIKEY;

export function signInRequest(data: SignInRequestData) {
  data['apiKey'] = APIKEY;
  data['grant_type'] = 'password';

  return api
    .post('/auth/token', data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      // console.error(error.response.data.message);
      return error.response.data;
    });
}

export function fetchUser() {
  return api
    .get('/player/me/status')
    .then(response => {
      const user = response.data;
      if (user) {
        return user;
      } else {
        document.dispatchEvent(new CustomEvent('signout'));
      }
    })
    .catch(error => {
      // console.log(error);
    });
}

export function registerUser(user: any) {
  return api
    .put('/database/registration__c', user, {
      headers: {
        Authorization: 'Basic ' + btoa(`${APIKEY}:${process.env.REACT_APP_REGISTRATION_SECRETKEY}`),
      },
    })
    .then(response => {
      const user = response.data;
      return user;
    })
    .catch(error => {
      // console.log(error);
    });
}

export async function recoverPasswordRequest(data: SignInRequestData) {
  data['apiKey'] = APIKEY;
  data['grant_type'] = 'password';

  try {
    const response = await api.post('/auth/token', data);
    return response.data.access_token;
  } catch (error) {
    console.log(error);
  }
}

export async function changePassword(data: any) {
  try {
    const response = await api.put(
      `/player/password?player=${data._id}&old_password=${data.old_password}&new_password=${data.new_password}`,
    );
    if (response.data.errorCode) {
      throw new Error('Houve um problema ao atualizar a senha. Por favor, tente novamente.');
    }
    return response.data;
  } catch (error) {
    throw new Error('Houve um problema ao atualizar a senha. Por favor, tente novamente.');
  }
}

export function getRoles(userId: string) {
  return api
    .get(`/database/principal?q=_id:"${userId}"`)
    .then(response => {
      const role = response.data[0];
      if (role) {
        return role;
      } else {
        document.dispatchEvent(new CustomEvent('signout'));
      }
    })
    .catch(error => {
      // console.log(error);
    });
}

export async function recoverPassword(id: string) {
  const APIKEY =
    !!(window as any).FUNIFIER_ENV?.apikey && typeof (window as any).FUNIFIER_ENV?.apikey === 'string'
      ? (window as any).FUNIFIER_ENV?.apikey
      : process.env.REACT_APP_APIKEY;

  const config = {
    headers: {
      Authorization: `Basic ${btoa(APIKEY)}`,
    },
  };

  const { data } = await api.get(`player/password/change?player=${id.toLocaleLowerCase().trim()}`, config);

  if (!Array.isArray(data) || !data.length) {
    return undefined;
  } else {
    return data;
  }
}

export async function updatePassword(payload: any) {
  try {
    const { username, password, code } = payload;
    const response = await api.put(
      `/player/password?player=${username.toLocaleLowerCase().trim()}&new_password=${password}&code=${code}`,
    );
    if (response.data.type === 'OK') {
      return true;
    } else {
      throw new Error('It was not possible to update your password');
    }
  } catch (error: any) {
    throw new Error('It was not possible to update your password');
  }
}

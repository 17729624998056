import { api } from './api';
import { AppearanceInterface } from 'types';

// Obter Aparência salva no Funifier
export async function getAppearance(): Promise<AppearanceInterface | undefined> {
  const APIKEY =
    !!(window as any).FUNIFIER_ENV?.apikey && typeof (window as any).FUNIFIER_ENV?.apikey === 'string'
      ? (window as any).FUNIFIER_ENV?.apikey
      : process.env.REACT_APP_APIKEY;

  const config = {
    headers: {
      Authorization: `Basic ${btoa(APIKEY)}`,
    },
  };

  const { data } = await api.get(`database/appearance__c`, config);

  if (!Array.isArray(data) || !data.length) {
    return undefined;
  } else {
    return data[0];
  }
}

import { api } from './api';

export async function getGoogleAnalitics() {
  const APIKEY =
    !!(window as any).FUNIFIER_ENV?.apikey && typeof (window as any).FUNIFIER_ENV?.apikey === 'string'
      ? (window as any).FUNIFIER_ENV?.apikey
      : process.env.REACT_APP_APIKEY;

  const config = {
    headers: {
      Authorization: `Basic ${btoa(APIKEY)}`,
    },
  };

  const { data } = await api.get(`database/authentication__c`, config);

  if (!Array.isArray(data) || !data.length) {
    return undefined;
  } else {
    return data[0].analytics;
  }
}

export async function getAuthTypes() {
  const APIKEY =
    !!(window as any).FUNIFIER_ENV?.apikey && typeof (window as any).FUNIFIER_ENV?.apikey === 'string'
      ? (window as any).FUNIFIER_ENV?.apikey
      : process.env.REACT_APP_APIKEY;

  const config = {
    headers: {
      Authorization: `Basic ${btoa(APIKEY)}`,
    },
  };

  const { data } = await api.get(`database/authentication__c`, config);

  if (!Array.isArray(data) || !data.length) {
    return undefined;
  } else {
    return data[0].authentication;
  }
}

export async function getRegisterTypes() {
  const APIKEY =
    !!(window as any).FUNIFIER_ENV?.apikey && typeof (window as any).FUNIFIER_ENV?.apikey === 'string'
      ? (window as any).FUNIFIER_ENV?.apikey
      : process.env.REACT_APP_APIKEY;

  const config = {
    headers: {
      Authorization: `Basic ${btoa(APIKEY)}`,
    },
  };

  const { data } = await api.get(`database/authentication__c`, config);

  if (!Array.isArray(data) || !data.length) {
    return undefined;
  } else {
    return data[0].register;
  }
}

export async function registerPlayer(payload: any) {
  const APIKEY =
    !!(window as any).FUNIFIER_ENV?.apikey && typeof (window as any).FUNIFIER_ENV?.apikey === 'string'
      ? (window as any).FUNIFIER_ENV?.apikey
      : process.env.REACT_APP_APIKEY;

  const config = {
    headers: {
      Authorization: `Basic ${btoa(APIKEY)}`,
    },
  };

  const { data } = await api.put(`database/player_register__c`, payload, config);
  return data;
}

import { CardCourse } from 'components';
import { BsThreeDots } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';

import { CoursesInterface } from 'types';

import './style.scss';
import { useEffect, useState } from 'react';

export type BoxCoursesProps = {
  courses: CoursesInterface[];
  title: string;
  maxLength?: number;
  children?: React.ReactNode;
  className?: string;
  classNameCards?: string;
  style?: React.CSSProperties;
  [key: string]: any;
  profiles?: string[];
};

export function BoxCourses({
  courses,
  title,
  maxLength,
  children,
  className,
  classNameCards,
  style,
  profiles,
  ...rest
}: BoxCoursesProps) {
  const location = useLocation();
  const [filteredCourses, setFilteredCourses] = useState<CoursesInterface[]>([]);

  useEffect(() => {
    if (profiles && profiles.length > 0) {
      const normalizedProfiles = profiles.map(el => el.toLowerCase());

      const updatedCourses = courses.filter(course => {
        if (course.extra && course.extra.teams && course.extra.teams.length > 0) {
          return course.extra.teams.some(team => normalizedProfiles.includes(team.replace(/\s/g, '').toLowerCase()));
        } else {
          return course;
        }
      });

      setFilteredCourses(updatedCourses);
    } else {
      setFilteredCourses(courses);
    }
  }, [courses, profiles]);

  return (
    <section className={`box-courses ${className || ''}`} style={style} {...rest}>
      {!!title?.length && (
        <div className="box-courses__head flex justify-between items-center mb-3 lg:mb-7">
          <h2 className="font-medium">
            {title} <span>({filteredCourses ? filteredCourses.length : courses.length})</span>
          </h2>

          <div className="box-courses__actions hidden lg:block">
            {!!courses.length && (
              <button>
                <BsThreeDots />
              </button>
            )}
          </div>
        </div>
      )}

      {!!filteredCourses.length && (
        <>
          <div className={`box-courses__cards block lg:flex justify-between items-stretch lg:-mx-5 ${classNameCards || ''}`}>
            {filteredCourses.slice(0, typeof maxLength === 'number' ? maxLength : filteredCourses.length).map((course, index) => (
              <CardCourse
                key={course._id}
                course={course}
                className={`${filteredCourses.length > 1 ? 'w-full lg:w-2/4' : 'w-full'} lg:p-5 mb-3 lg:mb-0`}
              />
            ))}
          </div>

          {/* {filteredCourses.length > 2 && !location.pathname.match(/cursos/gi) ? (
            <Link to={`/cursos`} className="relative flex items-center justify-center lg:hidden">
              Ver todos os cursos
            </Link>
          ) : null} */}
        </>
      )}

      {children}
    </section>
  );
}

import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Header, SideNav, Splash } from 'components';
import { useAuth, useBasic } from 'contexts';
import PATHS from 'routes/paths';
import { ChildrenProp } from 'types';


import Logo from 'assets/images/playdps.png';


export default function Layout({ children }: ChildrenProp) {
  const location = useLocation();
  const { appearance, fullLoading, showHeader, customClass, showSideNav, sideNavOpened, setSideNavOpened } = useBasic();
  const { user } = useAuth();

  const verifyRoute = useCallback(() => {
    const routes = PATHS.filter(route => !route.onlyInRouter);
    const getNavActiveIndex = () =>
      routes.findIndex(
        route =>
          location.pathname === route.pathname ||
          (location.pathname.match(/recompensas/gi) && route.pathname.match(/recompensas/gi)) ||
          (location.pathname.match(/configuracoes/gi) && route.pathname.match(/configuracoes/gi)),
      );

    if (getNavActiveIndex() < 0) {
      setSideNavOpened(false);
    } else {
      setSideNavOpened(true);
    }
  }, [location.pathname, setSideNavOpened]);

  useEffect(() => {
    window.scroll(0, 0);
    verifyRoute();
  }, [location, verifyRoute]);

  return (
    <>
      <div
        className={`container ${typeof customClass === 'string' && customClass.trim().length ? `${customClass.trim()}` : ''} ${
          !user || !showSideNav ? 'no-aside' : ''
        } ${!sideNavOpened ? 'aside-min' : 'aside-full'}`}
      >
        {showSideNav && !!user && <SideNav />}

<div  className="flex-1 flex flex-col">
        {!showSideNav && appearance ? <div className='flex items-center gap-4 p-2 px-3 rounded-xl' style={{background: appearance.layout.bgPage?.length ? appearance.layout.bgPage : '#503e9d'}}><div className='w-32'><img
            src={appearance?.images?.logo?.length ? appearance.images.logo : Logo}
            alt={appearance?.title?.length ? appearance.title : 'PlayDPS :: Games for Data Privacy & Security'}
          /></div>{appearance.images.partnerlogo ? <div className='w-32'><a href={appearance.partnerurl} target="_blank" rel="noreferrer"><img
          src={appearance?.images?.partnerlogo?.length ? appearance.images.partnerlogo : Logo}
          alt={appearance?.title?.length ? appearance.partnerurl : 'Partner Logo'}
        /></a></div> : null}</div> : null}

        <main className="flex flex-col" style={{width: '100%', minHeight:!showSideNav?'calc(100vh - 110px)': ''}}>
          {showHeader && <Header />}
          {children}
        </main>
        </div>

        {/* {showFooter && <Footer />} */}

        {/* <Cookies /> */}
      </div>

      {!!fullLoading && <Splash />}
    </>
  );
}

import { useEffect, useMemo, useState, useCallback } from 'react';
import { BoxCourses, BoxNextLessons } from 'components';
import { Placeholder } from 'components/Placeholder';
import { useBasic, useCourses, useAuth } from 'contexts';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import { getCourses, getDetailContent, getAllBanners } from 'services';
import { Banner } from 'types';

export default function HomePage() {
  const navigate = useNavigate();
  const { profileUser, user } = useAuth();
  const { width, appearance } = useBasic();
  const { listCourses, nextLessons } = useCourses();

  const [banner, setBanner] = useState<Banner | undefined>(undefined);
  const [isBannerLoaded, setIsBannerLoaded] = useState(false);

  const titleNextLessons = useMemo(() => {
    if (!nextLessons) return '';

    if (nextLessons.length > 1) {
      if (width > 900) {
        return 'Próximos conteúdos';
      } else {
        return 'Próximo conteúdo';
      }
    } else if (nextLessons.length === 1) {
      return 'Próximo conteúdo';
    } else {
      return 'Sua jornada começa aqui!';
    }
  }, [nextLessons, width]);

  const loadCourses = useCallback(async () => {
    if (user && (!user.challenges['startCourse'] || user.challenges['startCourse'] === 0)) {
      const courses = await getCourses(user?._id);

      if (courses) {
        courses.forEach(element => {
          const title = element.title
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLocaleLowerCase();
          if ((title === 'inicio' || title === 'start') && element.extra.showOnLogin) {
            getDetailContent(element._id).then(function (res) {
              const content = res || [];
              if(content.length > 0){
                navigate(`/cursos/${element._id}/${element._id}/${content[0]._id}`);
              }
            });
          }
        });
      }
    }
  }, [user, navigate]);

  useEffect(() => {
    loadCourses();
  }, [loadCourses]);

  useEffect(() => {
    const loadBanners = async () => {
      const data = await getAllBanners();
      setBanner(data[0]);
      setIsBannerLoaded(true);
    };

    loadBanners();
  }, []);

  useEffect(() => {
    if (appearance?.navigation?.initialUrl) {
      navigate(appearance.navigation.initialUrl);
    }
  }, [appearance, navigate]);

  if (!Array.isArray(listCourses) || !Array.isArray(nextLessons) || !isBannerLoaded) {
    return (
      <>
        <div className="flex flex-col space-y-6">
          <div className="flex flex-row space-x-3">
            <Placeholder className="w-10 h-10 rounded-full" />
            <Placeholder className="flex-1 h-10 rounded-full" />
          </div>

          <Placeholder className="h-64" />

          <div className="grid grid-cols-3 h-48 gap-6">
            <Placeholder />
            <Placeholder />
            <Placeholder />
          </div>
        </div>
      </>
    );
  }

  if (!listCourses.length) {
    return (
      <>
        <h2 className="block text-center p-6">Nenhum curso encontrado.</h2>
      </>
    );
  }

  return (
    <>
      <BoxNextLessons
        className="pb-6 lg:pb-0 border-b lg:border-b-0 mb-6 lg:mb-12"
        lessons={nextLessons}
        title={titleNextLessons}
        maxLength={width >= 1366 ? 2 : 1}
        banner={banner}
      />
      <BoxCourses courses={listCourses} title="Seus Cursos" maxLength={width > 1440 ? 3 : 2} profiles={profileUser?.roles} />
    </>
  );
}

import { api } from './api';
import { FaqInterface, TermsInterface, PrivacyInterface } from 'types';
import { getServerTime, getUser } from 'services';

// Obter lista de questões do FAQ
export async function getFaq(): Promise<FaqInterface[]> {
  const { data } = await api.get(`database/faq__c`);

  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

// Obter Termos de Uso
export async function getTerms(): Promise<TermsInterface | undefined> {
  const APIKEY =
    !!(window as any).FUNIFIER_ENV?.apikey && typeof (window as any).FUNIFIER_ENV?.apikey === 'string'
      ? (window as any).FUNIFIER_ENV?.apikey
      : process.env.REACT_APP_APIKEY;

  const config = {
    headers: {
      Authorization: `Basic ${btoa(APIKEY)}`,
    },
  };

  const { data } = await api.get(`database/terms`, config);

  if (!Array.isArray(data) || !data.length) {
    return undefined;
  } else {
    return data[0];
  }
}

// Obter Política de privacidade
export async function getPrivacy(): Promise<PrivacyInterface | undefined> {
  const APIKEY =
    !!(window as any).FUNIFIER_ENV?.apikey && typeof (window as any).FUNIFIER_ENV?.apikey === 'string'
      ? (window as any).FUNIFIER_ENV?.apikey
      : process.env.REACT_APP_APIKEY;

  const config = {
    headers: {
      Authorization: `Basic ${btoa(APIKEY)}`,
    },
  };

  const { data } = await api.get(`database/privacy`, config);

  if (!Array.isArray(data) || !data.length) {
    return undefined;
  } else {
    return data[0];
  }
}

// Enviar mensagem de contato
export async function sendMessage(type: string, subject: string, message: string): Promise<any> {
  if (!type || !subject || !message) return null;

  const created = await getServerTime();
  const user = await getUser();

  const obj = {
    userId: user._id,
    userName: user.name,
    userEmail: user.email,
    type,
    subject,
    message,
    created,
    read: false,
  };

  const { data } = await api.post(`database/contact__c`, obj);

  return data;
}

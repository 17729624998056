import { toast } from 'react-toastify';
import { ActionLog, LessonLikesInterface } from 'types/funifier';
import { api } from './api';

// import { Attributes } from '../models/ActionLog.types';
// import { storageGet } from './storage';

// const useQuery = () => {
//   return new URLSearchParams(window.location.search);
// };

export const actionLog = (actionId: string, attributes?: any, _id?: string | null | undefined, notificationType = 'modal') => {
  return new Promise((resolve, reject) => {
    // const global = storageGet('global'),

    const _attributes = Object.assign({}, attributes);

    // query = useQuery();
    // _attributes.time = new Date().getTime();

    const payload: any = {
      userId: 'me',
      actionId: actionId,
      time: new Date().getTime(),
      attributes: _attributes,
    };

    if (typeof _id === 'string') {
      payload._id = _id;
    }

    api
      .post('/action/log?async=false', payload)
      .then(response => {
        // if (response.data.achievements.length) {
        //   const event = new Event('reload:user');
        //   document.dispatchEvent(event);

        //   try {
        //     if (response.data.achievements) {
        //       const hasAchievement = response.data.achievements.find((achievement: any) => achievement.type === 1);
        //       if (!!hasAchievement) {
        //         if (notificationType === 'modal') {
        //           window.postMessage({ type: 'achievement', value: { name: '' } }, '*');
        //         } else {
        //           toast(`🚀 You've got new achievements!`, {
        //             position: 'top-right',
        //             autoClose: 5000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //           });
        //         }
        //       }
        //     }
        //   } catch (err) {
        //     console.log(err);
        //   }
        // }

        resolve(response.data);
      })
      .catch(error => {
        // reject(error);
        resolve(null);
      });
  });
};

export const actionLogBulk = (actionId: string, data: any[]) => {
  return new Promise((resolve, reject) => {
    const payload: ActionLog[] = data.map(action => {
      return {
        userId: 'me',
        actionId: actionId,
        time: action.date || new Date().getTime(),
        attributes: {
          ...action,
        },
      };
    });

    // console.log(payload);

    // return;

    api
      .post('/action/log/bulk?async=false', payload)
      .then(response => {
        const event = new Event('reload:user');
        document.dispatchEvent(event);
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export async function getActions(actionId: string): Promise<ActionLog[]> {
  try {
    const { data } = await api.get(`action/log`, {
      params: { player: 'me', action: actionId, orderby: 'time', reverse: true },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export const actionLogQuest = (operation: string, player: string, item: string) => {
  return new Promise((resolve, reject) => {
    const payload = {
      operation: operation,
      item: item,
      player: player,
    };

    api
      .put('/database/social_quests_log__c', payload)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getLessonLikes = async (
  player: string | undefined,
  lessonID: string | undefined,
): Promise<LessonLikesInterface | null> => {
  if (!player || !lessonID) return null;

  const payload = [
    {
      $facet: {
        like_content: [
          {
            $match: {
              actionId: 'like_content',
              'attributes.contentId': lessonID,
            },
          },
          {
            $group: {
              _id: '$actionId',
              total: {
                $sum: 1,
              },
            },
          },
        ],
        dislike_content: [
          {
            $match: {
              actionId: 'dislike_content',
              'attributes.contentId': lessonID,
            },
          },
          {
            $group: {
              _id: '$actionId',
              total: {
                $sum: 1,
              },
            },
          },
        ],
        playerLiked: [
          {
            $match: {
              userId: player,
              actionId: 'like_content',
              'attributes.contentId': lessonID,
            },
          },
          {
            $group: {
              _id: '$actionId',
              total: {
                $sum: 1,
              },
            },
          },
        ],
        playerDisliked: [
          {
            $match: {
              userId: player,
              actionId: 'dislike_content',
              'attributes.contentId': lessonID,
            },
          },
          {
            $group: {
              _id: '$actionId',
              total: {
                $sum: 1,
              },
            },
          },
        ],
        playerLogObj: [
          {
            $match: {
              userId: player,
              'attributes.contentId': lessonID,
              $or: [{ actionId: 'like_content' }, { actionId: 'dislike_content' }],
            },
          },
        ],
      },
    },
    {
      $project: {
        qtdLikes: {
          $ifNull: [
            {
              $arrayElemAt: ['$like_content.total', 0],
            },
            0,
          ],
        },
        qtdDislikes: {
          $ifNull: [
            {
              $arrayElemAt: ['$dislike_content.total', 0],
            },
            0,
          ],
        },
        playerLiked: {
          $ifNull: [
            {
              $arrayElemAt: ['$playerLiked.total', 0],
            },
            false,
          ],
        },
        playerDisliked: {
          $ifNull: [
            {
              $arrayElemAt: ['$playerDisliked.total', 0],
            },
            false,
          ],
        },
        playerLogObj: {
          $ifNull: [
            {
              $arrayElemAt: ['$playerLogObj', 0],
            },
            false,
          ],
        },
      },
    },
  ];

  const { data } = await api.post(`database/action_log/aggregate`, payload);

  if (!Array.isArray(data)) {
    return null;
  } else {
    if (data.length) {
      return data[0];
    } else {
      return null;
    }
  }
};

import { Badges, Challenges, Placeholder, RecentAchievements, UserStats } from 'components';
import UserInfo from 'components/UserInfo';
import { useAuth, useBasic } from 'contexts';
import { useEffect, useState } from 'react';

import './style.scss';

export default function ProfilePage() {
  const { user } = useAuth();
  const { setShowHeader } = useBasic();

  useEffect(() => {
    setShowHeader(false);

    return () => {
      setShowHeader(true);
    };
  }, [setShowHeader]);

  // if (!user) {
  //   return (
  //     <>
  //       <div className="flex flex-col space-y-6">
  //         <div className="flex flex-row space-x-3">
  //           <Placeholder className="w-10 h-10 rounded-full" />
  //           <Placeholder className="flex-1 h-10 rounded-full" />
  //         </div>

  //         <Placeholder className="h-64" />

  //         <div className="grid grid-cols-3 h-48 gap-6">
  //           <Placeholder />
  //           <Placeholder />
  //           <Placeholder />
  //         </div>
  //       </div>
  //     </>
  //   );
  // }

  return (
    <>
      <section id="profile">
        <h2 className="text-xl lg:text-3xl mb-5 lg:mb-8">Seu perfil</h2>

        <div className="head shadow-md rounded-md p-4 block lg:flex items-stretch justify-between mb-5 lg:mb-8">
          <UserInfo />
          <UserStats className="lg:border-l lg:border-gray-100 lg:border-solid block lg:flex items-stretch justify-between" />
        </div>

        <div className="grid md:grid-cols-2 gap-4 items-start">
        <div className="flex flex-col-reverse md:flex-col gap-4">
          <Badges className="shadow-md rounded-md p-4" />
          <RecentAchievements className="shadow-md rounded-md p-4" />
          </div>
          <div className="flex flex-col gap-4">
          <Challenges className="shadow-md rounded-md p-4" />
          </div>
        </div>
      </section>
    </>
  );
}

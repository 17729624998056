import { useCallback, useEffect, useState } from 'react';

import { useAuth, useCourses, useModal } from 'contexts';
import { actionLog, getQuestionsQuiz, registerFolderLog } from 'services';
import { DetailContentInterface } from 'types';
import { Timer } from 'components';
import { shuffleArray } from 'utils';

import './style.scss';
import { FaChevronRight } from 'react-icons/fa';

type QuizProps = {
  content: DetailContentInterface;
  className?: string;
  [key: string]: any;
};

export function Quiz({ content, className, ...rest }: QuizProps) {
  const { getFullProgress } = useCourses();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [questionList, setQuestionList] = useState<any[]>();
  const [step, setStep] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState<any>();
  const [currentQuestionAnswered, setCurrentQuestionAnswered] = useState<any>();
  const [score, setScore] = useState(0);

  async function handleAnswer(item: any) {
    if (!user || currentQuestionAnswered) return;

    if (item.grade === 1) {
      setScore(score + 1);
    }
    setCurrentQuestionAnswered(item.answer);
  }

  function handleNextQuestion() {
    if (!questionList) return;

    setCurrentQuestionAnswered(undefined);

    if (questionIndex + 1 >= questionList?.length) {
      setStep(2);
      submitResult();
    } else {
      setQuestionIndex(questionIndex + 1);
    }
  }

  const submitResult = useCallback(() => {
    // await actionLog('quiz', { score: score * 10 });

    registerFolderLog(user?._id, content._id, 'done').finally(() => {
      getFullProgress();
    });
  }, [content._id, getFullProgress, user?._id]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const questions = await getQuestionsQuiz(content.content);

      const shuffledQuestions = questions.map((item: any) => {
        return {
          ...item,
          choices: shuffleArray(item.choices),
        };
      });

      setQuestionList(shuffledQuestions);
    }

    if (step === 1 && !questionList && !!content) {
      loadData();
    }
  }, [content, questionList, step, submitResult]);

  function getAnswerClassName(item: any) {
    const className = 'bg-white text-orange-600';
    const correctClassName = 'text-white bg-green-600';
    const incorrectClassName = 'text-white bg-red-600';

    if (currentQuestionAnswered) {
      if (item.grade === 1) {
        return correctClassName;
      } else if (currentQuestionAnswered === item.answer) {
        return incorrectClassName;
      }
      return className;
    } else {
      return `cursor-pointer ${className}`;
    }
  }

  useEffect(() => {
    try {
      setLoading(false);
      if (questionList && questionList.length > 0) {
        setCurrentQuestion(questionList[questionIndex]);
      }
    } catch (e) {}
  }, [questionList, questionIndex]);

  // TO DO: Error Boundary
  if (!content) return null;
  // throw new Error("'content' is required attribute.");

  return (
    <div className={`quiz-component flex-1 w-full relative ${className || ''}`}>
      <div className="flex flex-col relative w-full h-full">
        <div className="w-full h-full relative overflow-hidden p-4 lg:p-10">
          <div className="min-h relative h-full text-center flex flex-col justify-center items-center">
            <>
              {!step && (
                <div className="text-white bg-opacity-80 p-0 lg:p-10 rounded-xl">
                  <h1 className="text-xl lg:text-3xl mb-3 font-bold">{content.title}</h1>
                  <button
                    className="bg-orange py-2 lg:py-3 px-3 font-bold text-sm lg:text-lg rounded-full"
                    type="button"
                    onClick={() => setStep(1)}
                  >
                    INICIAR QUIZ
                  </button>
                </div>
              )}

              {!!loading && (
                <div className="text-white bg-opacity-80 p-0 lg:p-10 rounded-xl">
                  <h1 className="text-lg lg:text-xl">Carregando...</h1>
                </div>
              )}

              {!loading && currentQuestion && step === 1 && (
                <div className="bg-opacity-90 p-0 lg:p-10 rounded-xl min-w-2xl max-w-2xl">
                  <div className="flex flex-row items-center justify-center bg-white rounded-full text-center shadow-xl mb-3 p-2">
                    <h1 className="text-base lg:text-xl text-orange-500 font-bold">
                      Questão {questionIndex + 1} de {questionList?.length}
                    </h1>
                  </div>
                  <div className="bg-white p-4 rounded-xl shadow-xl text-black">
                    <p className="text-base lg:text-xl" dangerouslySetInnerHTML={{ __html: currentQuestion.question }} />
                  </div>
                  <div className="py-2 font-bold text-white text-sm lg:text-lg">Escolha a sua resposta:</div>
                  <div className="space-y-2">
                    {currentQuestion.type === 'MULTIPLE_CHOICE' ? (
                      currentQuestion.choices.map((item: any) => (
                        <div
                          key={item._id}
                          onClick={() => handleAnswer(item)}
                          className={`p-2 ${getAnswerClassName(item)} border rounded-xl text-base lg:text-lg`}
                        >
                          {item.label}
                        </div>
                      ))
                    ) : currentQuestion.type === 'TRUE_FALSE' ? (
                      <>
                        <div
                          onClick={() =>
                            handleAnswer({
                              answer: '1',
                              label: 'Verdadeiro',
                              grade: currentQuestion.correctAnswer === true ? 1 : 0,
                            })
                          }
                          className={`p-2 ${getAnswerClassName({
                            answer: '1',
                            label: 'Verdadeiro',
                            grade: currentQuestion.correctAnswer === true ? 1 : 0,
                          })} border rounded-xl text-base lg:text-lg`}
                        >
                          Verdadeiro
                        </div>
                        <div
                          onClick={() =>
                            handleAnswer({ answer: '2', label: 'Falso', grade: currentQuestion.correctAnswer === false ? 1 : 0 })
                          }
                          className={`p-2 ${getAnswerClassName({
                            answer: '2',
                            label: 'Falso',
                            grade: currentQuestion.correctAnswer === false ? 1 : 0,
                          })} border rounded-xl text-base lg:text-lg`}
                        >
                          Falso
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  {currentQuestionAnswered && (
                    <div className="mt-4">
                      <button
                        onClick={handleNextQuestion}
                        className="mx-auto p-3 rounded flex text-xl text-white items-center justify-center border"
                      >
                        Próxima questão <FaChevronRight />
                      </button>
                    </div>
                  )}
                </div>
              )}

              {step === 2 && (
                <div className="text-white  bg-opacity-80 p-10 rounded-xl space-y-3">
                  <h1 className="text-3xl">Game Over!</h1>
                  <div className="leading-none py-3">
                    <div className="text-xl text-orange-500 ">Respostas corretas:</div>
                    <div className="text-2xl font-bold ">
                      {score} de {questionList?.length}
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

import { api } from './api';

export async function getGames() {
  const { data } = await api.post("find/games", {});
  if (!Array.isArray(data)) {
    return [];
  }
  return data;
}

export async function getGamesList() {
  const { data } = await api.post("find/games-list", {});
  if (!Array.isArray(data)) {
    return [];
  }
  return data;
}

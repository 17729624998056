import { AxiosError } from 'axios';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { api, fetchUser, getRoles, setAuthorizationHeader, signInRequest, updateUserStatus } from 'services';
import { ChildrenProp, User, ProfilesUser } from 'types';
import { clearStorage, createTokenCookies, getToken, removeTokenCookies } from 'utils';

interface SignInCredentials {
  username: string;
  password: string;
  profile?: { url: string };
}

interface AuthContextInterface {
  login: (credentials: SignInCredentials) => Promise<void | AxiosError>;
  loginWithToken: (access_token: string) => Promise<void>;
  signOut: (pathname?: string) => void;
  refreshUser: () => Promise<void>;
  user?: User;
  profileUser?: ProfilesUser;
  isAuthenticated: boolean;
  loadingUserData: boolean;
}

const AuthContext = createContext({} as AuthContextInterface);

function AuthProviderData(): AuthContextInterface {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [profileUser, setProfileUser] = useState<ProfilesUser | undefined>(undefined);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const navigate = useNavigate();
  const token = getToken();
  const isAuthenticated = Boolean(token);

  const signOut = useCallback(
    (pathname?: string) => {
      removeTokenCookies();
      clearStorage();
      setAuthorizationHeader(api.defaults, '');
      setLoadingUserData(false);
      setUser(undefined);
      setProfileUser(undefined);
      navigate(pathname || '/login');
    },
    [navigate],
  );

  async function login({ username, password }: SignInCredentials) {
    try {
      const auth = await signInRequest({ username, password });

      if (auth.message) {
        throw auth;
      }

      const { access_token, refreshToken, permissions, roles } = auth;

      createTokenCookies(access_token, refreshToken);
      setAuthorizationHeader(api.defaults, access_token);

      const data = await fetchUser();

      // const { name, image } = data;

      const userData = { _id: data._id, ...data, permissions, roles };

      setUser(userData);

      const profileData = await getRoles(userData._id);
      setProfileUser(profileData);

      return userData;
    } catch (error) {
      const err = error as AxiosError;
      console.log(err);
      throw err;
    }
  }

  async function loginWithToken(access_token: string) {
    try {
      if (!access_token) {
        return;
      }

      createTokenCookies(access_token, '');
      setAuthorizationHeader(api.defaults, access_token);

      const data = await fetchUser();

      const { name, image, extra } = data;

      const userData = { _id: data._id, ...data, extra, name, image, permissions: null, roles: null };

      setUser(userData);

      const profileData = await getRoles(userData._id);
      setProfileUser(profileData);

      return userData;
    } catch (error) {
      const err = error as AxiosError;
      // console.log(err);
      throw err;
    }
  }

  async function refreshUser() {
    const updatedUser = await updateUserStatus();
    setUser(updatedUser);
  }

  // useEffect(() => {
  // 	if (!token) signOut(pathname);
  // }, [pathname, token, signOut]);

  useEffect(() => {
    const token = getToken();

    async function getUserData() {
      setLoadingUserData(true);

      try {
        const response = await fetchUser();
        if (response) {
          // console.log("fetchUser",response);

          const { _id, name, image, email, permissions, roles } = response;
          const userData = { ...response, _id, name, image, email, permissions, roles };
          setUser(userData);

          const profileData = await getRoles(userData._id);
          setProfileUser(profileData);
        }
      } catch (error) {
        signOut();
      }

      setLoadingUserData(false);
    }

    if (!user && token) {
      setAuthorizationHeader(api.defaults, token);
      getUserData();
    }

    function handleSignoutEvent() {
      signOut();
    }

    document.addEventListener('reload:user', refreshUser, false);
    document.addEventListener('signout', handleSignoutEvent, false);

    return () => {
      document.removeEventListener('reload:user', refreshUser);
      document.removeEventListener('signout', handleSignoutEvent);
    };
  }, [user, signOut]);

  return {
    isAuthenticated,
    user,
    profileUser,
    loadingUserData,
    login,
    loginWithToken,
    signOut,
    refreshUser,
  };
}

const AuthProvider = ({ children }: ChildrenProp) => {
  const contextData: AuthContextInterface = AuthProviderData();
  return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};

const useAuth = (): AuthContextInterface => {
  const context = useContext(AuthContext);

  if (typeof context === 'undefined') {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export { AuthProvider, useAuth };

import { Link } from 'react-router-dom';

import PATHS from 'routes/paths';

import './style.scss';
import { useBasic } from 'contexts';
import { useCallback } from 'react';

export default function SettingsPage() {

  const { appearance } = useBasic();

  const isMenuActive = useCallback((pathname: string) => {
    if(appearance?.menu && Object.keys(appearance?.menu as any).includes(pathname)) {
      return (appearance?.menu as any)[pathname];
    }
    return true;
  },[appearance]);

  return (
    <section id="settings">
      <h2 className="text-xl lg:text-3xl mb-5 lg:mb-8">Configurações</h2>

      <div className="pages grid grid-col-2 px-4 gap-4">
        {PATHS.find(route => route.id === 'settings')?.children?.map(page => {

          if(!isMenuActive(page.id)) return null;

          return (
            <div className="page" key={page.id}>
              <Link to={page.pathname} className="flex items-center justify-between p-2 bg-white rounded-xl border">
                <span className="img">{page.icon}</span>
                <span className="text">{page.title}</span>
              </Link>
            </div>
          );
        })}
      </div>
    </section>
  );
}

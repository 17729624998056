import { useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { useAuth, useToast, useBasic, useModal } from 'contexts';
import { getRegisterTypes, registerPlayer } from 'services';
import { getTerms, getPrivacy } from 'services';
import { Html } from 'components';

import './style.scss';

type InputsForm = {
  _id: string;
  password: string;
  extra: { [key: string]: any }; // extra como objeto genérico
  [key: string]: any; // Para permitir campos dinâmicos
};

export default function RegisterPage() {
  const { toastify } = useToast();
  const navigate = useNavigate();

  const [sending, setSending] = useState(false);
  const [registerTypes, setRegisterTypes] = useState<any>({});

  const { setCustomClass } = useBasic();
  const [data, setData] = useState<any>();
  const { showModal } = useModal();

  const {
    register,
    handleSubmit: submit,
    formState: { errors },
    reset,
  } = useForm<InputsForm>();

  const handleSubmit: SubmitHandler<InputsForm> = async data => {
    setSending(true);
    // Implementar lógica de envio de dados
    let userRegister = {};

    if (registerTypes.emailPassword) {
      userRegister = {
        _id: data._id,
        password: data.password,
      };
    } else {
      userRegister = {
        _id: data.extra._id,
        password: data.extra.password,
        extra: data.extra,
      };
    }

    registerPlayer(userRegister).then(function (res) {
      if (res.status === 'OK') {
        toastify({
          content: res.message,
          type: 'success',
        });
        navigate('/login');
      } else {
        toastify({
          content: res.message,
          type: 'error',
        });
      }
    });

    setSending(false);
  };

  const loadAuthRegisterTypes = async () => {
    const register = await getRegisterTypes();
    setRegisterTypes(register);
  };

  useEffect(() => {
    loadAuthRegisterTypes();

    setCustomClass('register');

    return () => setCustomClass('');
  }, [setCustomClass]);

  const loadTerms = () => {
    getTerms().then(data => showModal('text', <Html content={data} />, 'text-black'));
  };

  const loadPrivacy = () => {
    getPrivacy().then(data => showModal('text', <Html content={data} />, 'text-black'));
  };

  return (
    <section id="register">
      <h2 className="text-xl lg:text-3xl mb-5 lg:mb-8"> Crie sua conta</h2>

      <form onSubmit={submit(handleSubmit)} noValidate>
        {registerTypes?.emailPassword && (
          <ul>
            <li>
              <label htmlFor="_id">E-mail:</label>
              <input
                type="text"
                id="_id"
                aria-invalid={errors._id ? 'true' : 'false'}
                {...register('_id', { required: 'Campo obrigatório.' })}
                disabled={sending}
                placeholder="E-mail"
              />
              {errors._id && <span role="alert">{String(errors._id.message)}</span>}
            </li>
            <li>
              <label htmlFor="password">Senha:</label>
              <input
                type="password"
                id="password"
                aria-invalid={errors.password ? 'true' : 'false'}
                {...register('password', { required: 'Campo obrigatório.' })}
                disabled={sending}
                placeholder="Senha"
              />
              {errors.password && <span role="alert">{String(errors.password.message)}</span>}
            </li>
          </ul>
        )}

        {registerTypes?.customFields && (
          <ul>
            {registerTypes.attributes
              .filter((e: any) => e.required)
              .map((field: any, index: number) => (
                <li key={index}>
                  {field.type === 'select' ? (
                    <>
                      <label htmlFor={field.name}>{field.title}:</label>
                      <select
                        id={field.name}
                        aria-invalid={errors[`extra.${field.name}`] ? 'true' : 'false'}
                        {...register(`extra.${field.name}`, { required: field.required ? 'Campo obrigatório.' : false })}
                        disabled={sending}
                      >
                        <option value="">Selecione</option>
                        {field.static_dropdown.map((item: any, i: number) => (
                          <option value={item.value} key={i}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      {errors.extra?.[`${field.name}`] && (
                        <span role="alert">{String(errors.extra?.[`${field.name}`]?.message)}</span>
                      )}
                    </>
                  ) : (
                    <>
                      <label htmlFor={field.name}>{field.title}:</label>
                      <input
                        type={field.type}
                        id={field.name}
                        aria-invalid={errors[`extra.${field.name}`] ? 'true' : 'false'}
                        {...register(`extra.${field.name}`, { required: field.required ? 'Campo obrigatório.' : false })}
                        disabled={sending}
                        placeholder={field.title}
                      />
                      {errors.extra?.[`${field.name}`] && (
                        <span role="alert">{String(errors.extra?.[`${field.name}`]?.message)}</span>
                      )}
                    </>
                  )}
                </li>
              ))}
          </ul>
        )}

        <ul className="mt-5">
          <li>
            <label htmlFor="accept">
              <input
                type="checkbox"
                id="accept"
                aria-invalid={errors.accept ? 'true' : 'false'}
                {...register('accept', { required: 'É necessário aceitar os termos.' })}
                disabled={sending}
              />{' '}
              Compreendo e aceito os{' '}
              <button type="button" className="underline" onClick={loadTerms}>
                Termos de Uso
              </button>{' '}
              e a{' '}
              <button type="button" className="underline" onClick={loadPrivacy}>
                {' '}
                Política de Privacidade
              </button>
              .
            </label>
            <span className="block">{errors.accept && <span role="alert">{String(errors.accept?.message)}</span>}</span>
          </li>
        </ul>

        <button type="submit" disabled={sending}>
          Enviar
        </button>

        <div className="flex items-center justify-center text-xs pt-2 space-x-2 mt-8">
          <Link to="/login" className="ml-1 text-blue-500">
            Voltar para Login
          </Link>
        </div>
      </form>
    </section>
  );
}

import { BreadcrumbInterface, CoursesInterface, DetailContentInterface, ProgressInterface } from 'types';
import { api } from './api';

// Obter lista de cursos
export async function getCourses(player: string | undefined): Promise<CoursesInterface[]> {
  if (!player) return [];

  const { data } = await api.post(`database/folder/aggregate`, [
    { $match: { type: 'course', parent: { $exists: false }, 'extra.showCourse': true } },
    { $sort: { 'extra.order': 1 } },
  ]);

  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

// Obtém detalhes do conteúdo de um folder
export async function getDetailContent(
  folder: string,
  cascade = true,
  appendData = true,
  match?: any,
): Promise<DetailContentInterface[] | null> {
  if (!folder) return null;

  const { data } = await api.post(
    `folder/content/aggregate?folder=${folder}&cascade=${cascade}&append_data=${appendData}`,
    typeof match !== 'undefined' ? [{ $match: match }, { $sort: { position: 1 } }] : [{ $sort: { position: 1 } }],
  );

  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

// Obtém detalhes do conteúdo de um folder do tipo Game e fala se completou ou não
export async function getDetailContentGame(
  folder: string,
  cascade = true,
  appendData = true,
  user: string,
): Promise<DetailContentInterface[] | null> {
  if (!folder) return null;

  const { data } = await api.post(`folder/content/aggregate?folder=${folder}&cascade=${cascade}&append_data=${appendData}`, [
    {
      $lookup: {
        from: 'folder_log',
        let: {
          id: '$_id',
        },
        pipeline: [
          {
            $match: {
              status: 'done',
              player: user,
              $expr: {
                $and: [
                  {
                    $eq: ['$item', '$$id'],
                  },
                ],
              },
            },
          },
        ],
        as: 'completed',
      },
    },
    {
      $addFields: {
        completed: {
          $size: '$completed',
        },
      },
    },
    {
      $sort: {
        'extra.position': 1,
      },
    },
  ]);

  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

// Obter itens filhos de uma pasta
export async function getChildItems(parent: string): Promise<any> {
  if (!parent) return null;

  const { data } = await api.post(`database/folder/aggregate`, [{ $match: { parent } }]);

  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

// Obter progresso com base em um folder
export async function getFolderProgress(player: string | undefined, folderID: string): Promise<ProgressInterface | null> {
  if (!player || !folderID) return null;

  const { data } = await api.post(`folder/progress`, { player, folder: folderID });

  if (!data) {
    return null;
  } else {
    return data;
  }
}

// Obter Breadcrumb
export async function getBreadcrumb(folderID: string): Promise<BreadcrumbInterface[] | null> {
  if (!folderID) return null;

  const { data } = await api.post(`folder/breadcrumb`, { folder: folderID });

  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

// Obter log de status
export async function getFolderLog(player: string | undefined, item: string, status?: string): Promise<any> {
  if (!player || !item) return null;

  const payload: any = { $match: { item, player } };

  if (typeof status !== 'undefined') {
    payload.$match.status = status;
  }

  const { data } = await api.post(`database/folder_log/aggregate`, [payload]);

  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

// Registrar log de status
export async function registerFolderLog(player: string | undefined, item: string, status: string, extra?: any): Promise<any> {
  if (!player || !item || !status) return null;

  const serverTime = await getServerTime();

  const payload: any = {
    item,
    player,
    status,
    extra: {
      date: serverTime,
    },
  };

  if (extra) {
    payload.extra = { ...payload.extra, ...extra };
  }

  const { data } = await api.post(`folder/log`, payload);

  const event = new Event('reload:user');
  document.dispatchEvent(event);

  return data;
}

// Obter horário do servidor - Para gravar o log corretamente (Modificar posteriormente com o Ricardo, para gravar diretamente pelo service sem a necessidade dessa requisição)
export async function getServerTime(): Promise<number> {
  const { data } = await api.post(`database/folder/aggregate`, [
    {
      $facet: {
        data: [
          {
            $match: {
              _id: 'IDInexistenteParaPegarHoraDoServidor',
            },
          },
          {
            $project: {
              _id: 1,
            },
          },
        ],
      },
    },
    {
      $project: {
        server_time: {
          $ifNull: [
            {
              $arrayElemAt: ['$data._id', 0],
            },
            '$$NOW',
          ],
        },
      },
    },
  ]);

  return data[0].server_time;
}

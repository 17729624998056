import { useEffect, useState } from 'react';

import { Placeholder } from 'components';
import { TermsInterface } from 'types';
import { useAuth, useBasic } from 'contexts';
import { getTerms, updateUserProfile, updateUserStatus } from 'services';
import { useNavigate } from 'react-router-dom';

import './style.scss';

export default function TermsPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<TermsInterface>();
  const { user } = useAuth();
  const { appearance } = useBasic();

  useEffect(() => {
    getTerms()
      .then(_data => setData(_data))
      .finally(() => setLoading(false));
  }, []);

  const acceptTerms = () => {
    if (user) {
      user.extra.acceptTerms = true;
      user.extra.acceptTermsDate = new Date();
    }
    updateUserProfile(user).then(_data =>
      updateUserStatus()
        .then(_data => console.log('update status'))
        .finally(() => navigate('/')),
    );
  };

  if (loading) {
    return (
      <div id="terms">
        <div className="grid gap-4">
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
          <Placeholder className="w-full" />
          <Placeholder className="w-full" />
          <Placeholder className="w-3/4" />
          <Placeholder className="w-full" />
          <Placeholder className="w-full" />
        </div>
      </div>
    );
  }

  return (
    <section id="terms">
      <h2 className="text-xl lg:text-3xl mb-5 lg:mb-8">Termos de uso</h2>

      <div className="content" dangerouslySetInnerHTML={{ __html: data?.content || '' }} />

      {!user?.extra.acceptTerms && (
        <button className="accept" onClick={acceptTerms}>
          Aceitar os termos de uso
        </button>
      )}
    </section>
  );
}

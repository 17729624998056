import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useToast } from 'contexts';
import { recoverPassword } from 'services';

type RecoveryFormInputs = {
  username: string;
};

export default function RequestRecoveryCode({ onSuccess }: { onSuccess: (username: string) => void }) {
  const { toastify } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RecoveryFormInputs>();
  const [sending, setSending] = useState(false);

  const onSubmit: SubmitHandler<RecoveryFormInputs> = async data => {
    setSending(true);
    try {
      await recoverPassword(data.username);
      toastify({
        content: 'Código de recuperação enviado para o seu e-mail.',
        type: 'success',
      });
      onSuccess(data.username);
    } catch (error) {
      toastify({
        content: 'Erro ao enviar código de recuperação.',
        type: 'error',
      });
    } finally {
      setSending(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <ul>
        <li>
          <label htmlFor="username">Informe seu e-mail para receber o código de segurança.</label>
          <input
            type="text"
            id="username"
            {...register('username', { required: 'Campo obrigatório.' })}
            disabled={sending}
            placeholder="E-mail"
            className="mt-4"
          />
          {errors.username && <span role="alert">{String(errors.username.message)}</span>}
          <button type="submit" disabled={sending} className="py-2 px-4">
            Enviar
          </button>
        </li>
      </ul>
    </form>
  );
}

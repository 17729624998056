import { api } from './api';

export async function getAllBanners(): Promise<any[]> {
  const { data } = await api.post(`database/banner_home__c/aggregate`, [{ $match: { active: true } }]);

  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { useAuth, useBasic } from 'contexts';
import PATHS from 'routes/paths';
import UserInfo from 'components/UserInfo';

import './style.scss';

import Logo from 'assets/images/playdps.png';

import { Route } from 'types';

export function SideNav() {
  const location = useLocation();
  const { user } = useAuth();

  const { sideNavOpened, width, messagesIDs, newsIDs, appearance } = useBasic();

  const [menuOpen, setMenuOpen] = useState(false);

  const routes = PATHS.filter(route => !route.onlyInRouter) as Route[];

  const getNavActiveIndex = useMemo(() => {
    return routes.findIndex(
      route =>
        location.pathname === route.pathname ||
        (location.pathname.match(/recompensas/gi) && route.pathname.match(/recompensas/gi)) ||
        (location.pathname.match(/configuracoes/gi) && route.pathname.match(/configuracoes/gi)),
    );
  }, [location.pathname, routes]);

  const getNumberNews: number = useMemo((): number => {
    if (newsIDs && newsIDs?.length) {
      const ids = newsIDs.filter(id => !id.read);

      return ids.length;
    } else {
      return 0;
    }
  }, [newsIDs]);

  const getNumberMessages: number = useMemo((): number => {
    if (messagesIDs) {
      const ids = [...messagesIDs.public, ...messagesIDs.private].filter(id => !id.read);
      return ids.length;
    } else {
      return 0;
    }
  }, [messagesIDs]);

  const appearanceStyle: any = useMemo(() => {
    return {
      '--color-menu-idle': appearance?.layout?.colorMenuIdle?.length ? appearance.layout.colorMenuIdle : '#ffffff',
      '--color-menu-active': appearance?.layout?.colorMenuActive.length ? appearance.layout.colorMenuActive : '#fb6d3a',
    };
  }, [appearance]);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('menu-open');
      window.scroll(0, 0);
    } else {
      document.body.classList.remove('menu-open');
    }
  }, [menuOpen]);

  if (!user) return null;

  return (
    <aside className={`${sideNavOpened ? 'full' : 'min'} ${menuOpen ? 'menu-open' : ''}`} style={appearanceStyle}>
      <button className="bt-menu" onClick={() => setMenuOpen(prevstate => !prevstate)}>
        <span />
      </button>

      <h1>
        <Link to="/" onClick={() => setMenuOpen(false)}>
          <img
            src={appearance?.images?.logo?.length ? appearance.images.logo : Logo}
            alt={appearance?.title?.length ? appearance.title : 'PlayDPS :: Games for Data Privacy & Security'}
          />
        </Link>
      </h1>

      {appearance?.images?.partnerlogo?.length ? (
        appearance?.partnerurl?.length ? (
          <a
            href={appearance?.partnerurl}
            target="_blank"
            rel="noopener noreferrer"
            className="partner"
            onClick={() => setMenuOpen(false)}
          >
            <img src={appearance.images.partnerlogo} alt="" />
          </a>
        ) : (
          <img src={appearance.images.partnerlogo} alt="" className="partner" />
        )
      ) : null}

      <nav
        style={{ '--nav-active-index': getNavActiveIndex } as React.CSSProperties}
        className={getNavActiveIndex < 0 ? `hide-bg-nav` : ''}
      >
        <span className="bg-nav">
          <span />
          <span />
        </span>
        <ul>
          {width <= 1023 && user ? (
            <li className="user">
              <UserInfo />
            </li>
          ) : null}
          {routes.map((route, index) => (
            <li id={`nav-${index}`} key={route.title} className="nav-item">
              {appearance?.menu[route.id] && (
                <Link
                  to={route.pathname}
                  className={`nav-link ${
                    location.pathname === route.pathname ||
                    (location.pathname.match(/recompensas/gi) && route.pathname.match(/recompensas/gi)) ||
                    (location.pathname.match(/configuracoes/gi) && route.pathname.match(/configuracoes/gi))
                      ? 'active'
                      : ''
                  }`}
                  data-tip={!sideNavOpened ? route.title : null}
                  onClick={() => setMenuOpen(false)}
                >
                  {route.icon}
                  <span className="flex items-center">
                    {route.title}
                    {route.id === 'news' && getNumberNews > 0 && (
                      <span className="number">{getNumberNews > 10 ? '+10' : getNumberNews}</span>
                    )}
                    {route.id === 'messages' && getNumberMessages > 0 && (
                      <span className="number">{getNumberMessages > 10 ? '+10' : getNumberMessages}</span>
                    )}
                  </span>
                </Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
      {!sideNavOpened && <ReactTooltip place="top" type="light" effect="solid" className="tooltip" />}
    </aside>
  );
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineDislike, AiOutlineLike } from 'react-icons/ai';
import { AiFillFileExcel, AiFillFileImage, AiFillFilePdf, AiFillFileWord, AiFillFileZip } from 'react-icons/ai';
import { FaFileAudio, FaFileCsv, FaFileDownload, FaFilePowerpoint, FaFileVideo } from 'react-icons/fa';
import { HiArrowLeft } from 'react-icons/hi';
import { FaTimes } from 'react-icons/fa';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { Placeholder, Quiz, Text, Video } from 'components';
import { useAuth, useBasic, useCourses } from 'contexts';
import { actionLog, getBreadcrumb, getDetailContent, getLessonLikes, registerFolderLog } from 'services';
import { BreadcrumbInterface, DetailContentInterface, File, LessonLikesInterface, LessonLikesPlayerObj } from 'types';
import { findProgressByID, formatBytes } from 'utils';
import { NextLessons } from './NextLessons';

import './style.scss';
import { GameContainer, GameFolder } from 'components/GameContainer';

export default function LessonPage() {
  const { user } = useAuth();
  const { setShowHeader } = useBasic();
  const { allProgress, getFullProgress } = useCourses();
  const { courseID, folderID, contentID } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [lessonContent, setLessonContent] = useState<DetailContentInterface[]>();
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbInterface[]>();
  const [loadingLikes, setLoadingLikes] = useState(true);
  const [likes, setLikes] = useState<LessonLikesInterface | null | undefined>();

  const getFolderPercentages = useMemo(() => {
    if (Array.isArray(allProgress) && allProgress.length && !!folderID) {
      const folderPercentage = findProgressByID(allProgress, folderID);

      if (!!folderPercentage) {
        return folderPercentage;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [allProgress, folderID]);

  const lesson = useMemo(() => {
    if (lessonContent?.length) {
      const content = lessonContent.find(x => x._id === contentID);

      if (content) {
        return content;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [contentID, lessonContent]);

  const getLikes = useCallback(async () => {
    setLoadingLikes(true);
    const _likes = await getLessonLikes(user?._id, contentID);
    setLikes(_likes);
    setLoadingLikes(false);
  }, [contentID, user]);

  useEffect(() => {
    if (folderID && contentID && !lessonContent) {
      setLoading(true);

      getDetailContent(folderID, false)
        .then(async data => {
          if (data?.length) {
            const breadcrumbs = await getBreadcrumb(folderID);

            if (breadcrumbs?.length) {
              setBreadcrumbs(breadcrumbs);
            }

            setLessonContent(data);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [contentID, folderID, lessonContent]);

  useEffect(() => {
    getLikes();

    console.log(lesson?.type);

    if (!!lesson?.type.match(/(quiz|game)/gi)) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }

    return () => setShowHeader(true);
  }, [getLikes, lesson, setShowHeader]);

  const handleLike: (value: string) => void = (actionID: string) => {
    if (loadingLikes) return null;

    setLoadingLikes(true);

    setLikes(prevstate => {
      if (typeof prevstate === 'object') {
        if (actionID === 'like_content') {
          return {
            ...(prevstate as LessonLikesInterface),
            playerLiked: 1,
            playerDisliked: false,
          };
        } else {
          return {
            ...(prevstate as LessonLikesInterface),
            playerLiked: false,
            playerDisliked: 1,
          };
        }
      }

      return prevstate;
    });

    actionLog(
      actionID,
      {
        contentId: contentID,
      },
      (likes?.playerLogObj as LessonLikesPlayerObj)?._id,
    )
      .then(() => {
        getLikes();
      })
      .finally(() => setLoadingLikes(false));
  };

  const registerLog = (contentId: string) => {
    registerFolderLog(user?._id, contentId, 'done').finally(() => {
      getFullProgress();
    });
  };

  const nextLessons: DetailContentInterface[] = useMemo((): DetailContentInterface[] => {
    if (!!lessonContent?.length && !!lesson && !!getFolderPercentages) {
      const obj: DetailContentInterface[] = [];

      lessonContent
        .sort((a, b) => a.position - b.position)
        .forEach((item, index) => {
          const lessonActive = item._id === lesson._id;
          const itemPercent = getFolderPercentages.items?.find(x => x._id === item._id);
          let lessonOpen: boolean;

          if (!!itemPercent && itemPercent.percent > 0) {
            lessonOpen = true;
          } else if (lessonActive) {
            lessonOpen = true;
          } else if (
            typeof getFolderPercentages.items !== 'undefined' &&
            getFolderPercentages.items[index - 1]?.percent === 100
          ) {
            lessonOpen = true;
          } else {
            lessonOpen = false;
          }

          obj.push({
            ...item,
            open: true, //lessonOpen,
            active: lessonActive,
            percent: itemPercent ? itemPercent.percent : 0,
          });
        });

      return obj;
    } else {
      return [];
    }
  }, [getFolderPercentages, lesson, lessonContent]);

  const mountBreadcrumb = () => {
    if (breadcrumbs && breadcrumbs.length > 1) {
      // const items = breadcrumbs.slice(1, breadcrumbs.length);

      return (
        <>
          {' '}
          {breadcrumbs.map((item, index) => (
            <span key={index}>
              {index === 0 && (
                <a className="underline" href={`/cursos/${item._id}`}>
                  {item.title}
                </a>
              )}
              {index > 0 && index + 1 < breadcrumbs.length && (
                <a className="underline" href={`/cursos/${courseID}/${folderID}/${contentID}`}>
                  {item.title}
                </a>
              )}
              {index > 0 && index + 1 === breadcrumbs.length && `${item.title}`}
              {index < breadcrumbs.length - 1 && <> • </>}
            </span>
          ))}
        </>
      );
    } else {
      return <></>;
    }
  };

  const getDocIcon = (extension: string) => {
    if (!!extension.match(/pdf/gi)) {
      return <AiFillFilePdf />;
    } else if (!!extension.match(/xls/gi)) {
      return <AiFillFileExcel />;
    } else if (!!extension.match(/csv/gi)) {
      return <FaFileCsv />;
    } else if (!!extension.match(/(zip|rar|tar|gz)/gi)) {
      return <AiFillFileZip />;
    } else if (!!extension.match(/doc/gi)) {
      return <AiFillFileWord />;
    } else if (!!extension.match(/ppt/gi)) {
      return <FaFilePowerpoint />;
    } else if (!!extension.match(/(png|jpg|jpeg|gif|bmp|webp)/gi)) {
      return <AiFillFileImage />;
    } else if (!!extension.match(/(mkv|mp4|avi|webm|wmv|mov)/gi)) {
      return <FaFileVideo />;
    } else if (!!extension.match(/(mp3|wav|wma|ogg|aac|flac)/gi)) {
      return <FaFileAudio />;
    } else {
      return <FaFileDownload />;
    }
  };

  useEffect(() => {
    if (lesson && lesson.data.showOnScreen) {
      const url = lesson.data.url;
      if (typeof url === 'object' && url && 'url' in url && typeof url.url === 'string') {
        const timerId = setTimeout(() => {
          registerLog(lesson._id);
        }, 60000);

        return () => clearTimeout(timerId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson]);

  function verifyDocument(lesson: any) {
    if (typeof lesson.data.url.url === 'string') {
      return lesson.data.url.url;
    }
  }

  if (!contentID && Array.isArray(allProgress) && allProgress.length) {
    if (getFolderPercentages?.items?.length) {
      return <Navigate to={`/cursos/${courseID}/${folderID}/${getFolderPercentages.items[0]._id}`} replace />;
    } else {
      return <Navigate to={`/erro-404`} replace />;
    }
  }

  if (loading) {
    return (
      <>
        <div className="flex flex-col space-y-6">
          <div className="flex flex-row space-x-3">
            <Placeholder className="w-10 h-10 rounded-full" />
            <Placeholder className="flex-1 h-10 rounded-full" />
          </div>

          <Placeholder className="h-64" />

          <div className="grid grid-cols-3 h-48 gap-6">
            <Placeholder />
            <Placeholder />
            <Placeholder />
          </div>
        </div>
      </>
    );
  }

  if (!lesson || !breadcrumbs?.length) {
    return <Navigate to={`/erro-404`} replace />;
  }

  return (
    <>
      <section className="lesson flex-1">
        <div className={`content ${!nextLessons?.length || !!lesson.type.match(/(quiz|game)/gi) ? 'w-full flex flex-col' : ''}`}>
          <div className="head">
            <div className={`crumbs ${!!lesson.type.match(/(quiz|game)/gi) ? 'has-btn-back text-left' : ''}`}>
              {!!breadcrumbs[0].title && (
                <p>
                  {/* <strong>{breadcrumbs[0].title}</strong> */}
                  {mountBreadcrumb()}
                  {!!lesson.type.match(/(quiz|game)/gi) && !!lesson.title && <span className="title">{lesson.title}</span>}
                </p>
              )}
            </div>

            {!!lesson.type.match(/(quiz|game)/gi) && (
              <button className="back" onClick={() => navigate(-1)}>
                Fechar
                <span className="ico ml-1">
                  <FaTimes />
                </span>
              </button>
            )}
          </div>

          {!lesson.type.match(/(quiz|game)/gi) && (
            <div className="content-data">
              <h2 className="title">{lesson.title}</h2>

              <div className="social">
                <button
                  className={`like ${!!likes?.playerLiked ? 'active' : ''}`}
                  onClick={() => (!likes?.playerLiked ? handleLike('like_content') : null)}
                  disabled={!!likes?.playerLiked}
                  title="Gostei"
                >
                  <AiOutlineLike />

                  {!!likes?.qtdLikes && <span>{likes.qtdLikes}</span>}
                </button>

                <button
                  className={`dislike ${!!likes?.playerDisliked ? 'active' : ''}`}
                  onClick={() => (!likes?.playerDisliked ? handleLike('dislike_content') : null)}
                  disabled={!!likes?.playerDisliked}
                  title="Não gostei"
                >
                  <AiOutlineDislike />
                </button>
              </div>
            </div>
          )}

          {!!lesson.data?.intro && !!lesson.type.match(/(video|doc)/gi) && <p className="intro mb-3">{lesson.data.intro}</p>}

          {!!lesson.data?.description && lesson.type !== 'text' && (
            <p className="description mb-3 text-left" dangerouslySetInnerHTML={{ __html: lesson.data.description }} />
          )}

          {lesson.type === 'video' && <Video content={lesson} />}

          {lesson.type === 'text' && <Text content={lesson} />}

          {lesson.type === 'quiz' && <Quiz content={lesson} />}

          {lesson.type.match(/game_*/gi) && <GameContainer id={GameFolder[lesson.type]} contentId={contentID} className="mb-5" />}

          {lesson.type === 'doc' && (
            <div>
              {lesson.data.showOnScreen ? (
                <embed src={verifyDocument(lesson)} type="" className="w-full min-h-[60vh]" id="embed" />
              ) : (
                <div className="download-file">
                  <a
                    href={(lesson.data.url as File).url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="doc"
                    download
                    onClick={() => registerLog(lesson._id)}
                  >
                    <span className="ico">{getDocIcon((lesson.data.url as File).extension)}</span>
                    <div>
                      <p className="title">Baixar arquivo</p>
                      <p className="meta">
                        {(lesson.data.url as File).extension?.replace(/\./gi, '').toUpperCase()} •{' '}
                        {formatBytes((lesson.data.url as File).size)}
                      </p>
                    </div>
                  </a>
                </div>
              )}
            </div>
          )}
        </div>

        {!!nextLessons?.length && !!courseID && !!folderID && !lesson.type.match(/(quiz|game)/gi) && (
          <div className="right-data">
            {!!nextLessons?.length && <NextLessons nextLessons={nextLessons} courseID={courseID} folderID={folderID} />}

            {/* {!!lesson.content?.length && <ExtraContent content={lesson.content} />} */}
          </div>
        )}
      </section>
    </>
  );
}

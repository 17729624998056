import { useCallback, useEffect, useState } from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';

import { useAuth, useBasic, useToast } from 'contexts';

import './style.scss';

// import Logo from 'assets/images/playdps.png';

import { UserManager } from 'oidc-client';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { actionLog } from 'services';
import { getAuthTypes, getRegisterTypes } from 'services';
import { parseUrlString } from 'utils';

type InputsForm = {
  username: string;
  password: string;
};

export default function AuthPage() {
  const { isAuthenticated, loginWithToken, login } = useAuth();
  const { setCustomClass, appearance } = useBasic();
  const { toastify } = useToast();

  const [isLoginEnabled, setIsLoginEnabled] = useState(false);

  const {
    register,
    handleSubmit: submit,
    watch,
    formState: { errors },
    reset,
  } = useForm<InputsForm>();

  const [loading, setLoading] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  // const [errors, setErrors] = useState<any>({});

  // const [params] = useSearchParams();

  const [authTypes, setAuthTypes] = useState<any>({});
  const [registerTypes, setRegisterTypes] = useState<any>({});

  const doLogin = useCallback(
    async (username: string, password: string) => {
      setLoading(true);

      try {
        await login({ username, password });
        actionLog('login');
      } catch (e) {
        setLoading(false);
        setInvalidUser(true);

        toastify({
          content: 'Usuário ou senha inválidos. Por favor, tente novamente.',
          type: 'error',
        });
      }
    },
    [login, toastify],
  );

  const doLoginWithToken = useCallback(
    async (token: string) => {
      try {
        await loginWithToken(token);
      } catch (e) {
        setLoading(false);
        setInvalidUser(true);
      }
    },
    [loginWithToken],
  );

  const loadAuthRegisterTypes = async () => {
    const auth = await getAuthTypes();
    setAuthTypes(auth);

    const register = await getRegisterTypes();
    setRegisterTypes(register);
  };

  useEffect(() => {
    loadAuthRegisterTypes();

    setCustomClass('auth');

    // const params = new URLSearchParams(window.location.search);

    const params = parseUrlString();

    const token = params.token;
    const agent = params.play;
    const secret = params.hs;

    console.log(agent, secret);

    setIsLoginEnabled(false);

    if (token) {
      setLoading(true);
      doLoginWithToken(token);
    } else if (agent && secret) {
      setLoading(true);
      doLogin(agent, secret);
    } else {
      setInvalidUser(true);
      setIsLoginEnabled(true);
    }

    return () => setCustomClass('');
  }, [doLogin, doLoginWithToken, login, loginWithToken, setCustomClass]);

  const handleSubmit: SubmitHandler<InputsForm> = async data => {
    if (loading) return;
    doLogin(data.username, data.password);
  };

  const loginWithSSO = () => {
    if (authTypes.sso) {
      const userManager = new UserManager({
        authority: authTypes.url,
        client_id: authTypes.clientId,
        redirect_uri: `${window.location.origin}/callback`,
        response_type: 'id_token token',
        scope: 'openid profile',
      });

      userManager.signinRedirect();
    }
  };

  if (isAuthenticated) {
    return <Navigate to={appearance?.navigation?.initialUrl || '/'} replace />;
  }

  return (
    <div className="container flex w-full h-[100vh]">
      <div className="auth-left w-[50%] flex items-center">
        <div>
          <img src={appearance?.images.logo} className="pointer-events-none" alt={appearance?.title} title={appearance?.title} />
        </div>
      </div>
      <div className="auth-right w-[50%] flex items-center">
        <div id="auth" className="w-[80%] mx-auto  flex flex-col items-center justify-center">
          <div className="header">
            <img
              src={appearance?.images.logoSecondary}
              className="pointer-events-none mx-auto my-5"
              alt={appearance?.title}
              title={appearance?.title}
            />
          </div>

          <div className="content">
            <p className="text-[2em]">
              <strong>Bem-vindo</strong>
            </p>
            <p className="text-[1.2em]">Comece preenchendo seu e-mail e senha</p>
            {loading && <div className="w-full border p-3 mt-8 rounded-full text-center font-bold bg-white">CARREGANDO...</div>}

            {!isLoginEnabled && invalidUser && !loading && (
              // <form action="" onSubmit={submit(handleSubmit)} noValidate>
              //   <ul>
              //     <li>
              //       <input
              //         type="text"
              //         id="username"
              //         aria-invalid={errors.username ? 'true' : 'false'}
              //         {...register('username', {
              //           required: 'Campo obrigatório.',
              //         })}
              //         disabled={loading}
              //         placeholder="Usuário"
              //       />
              //       {errors.username && <span role="alert">{errors.username.message}</span>}
              //     </li>
              //     <li>
              //       <input
              //         type="password"
              //         id="password"
              //         aria-invalid={errors.password ? 'true' : 'false'}
              //         {...register('password', {
              //           required: 'Campo obrigatório.',
              //         })}
              //         disabled={loading}
              //         placeholder="Senha"
              //       />
              //       {errors.password && <span role="alert">{errors.password.message}</span>}
              //     </li>
              //   </ul>

              //   <button type="submit" disabled={loading}>
              //     ACESSAR
              //   </button>
              // </form>
              <div className="w-full border p-3 mt-8 rounded-full text-center font-bold bg-white">
                VOCÊ NÃO TEM ACESSO A ESTE PRODUTO.
                <br />
                CONSULTE O ADMINISTRADOR.
              </div>
            )}

            {isLoginEnabled && invalidUser && !loading && (
              <form action="" onSubmit={submit(handleSubmit)} noValidate>
                {authTypes && authTypes.auto && (
                  <ul>
                    <li>
                      <input
                        type="text"
                        id="username"
                        aria-invalid={errors.username ? 'true' : 'false'}
                        {...register('username', {
                          required: 'Campo obrigatório.',
                        })}
                        disabled={loading}
                        placeholder="E-mail"
                      />
                      {errors.username && <span role="alert">{errors.username.message}</span>}
                    </li>
                  </ul>
                )}

                {authTypes && authTypes.emailPassword && (
                  <>
                    <ul>
                      <li>
                        <input
                          type="text"
                          id="username"
                          aria-invalid={errors.username ? 'true' : 'false'}
                          {...register('username', {
                            required: 'Campo obrigatório.',
                          })}
                          disabled={loading}
                          placeholder="email@email.com"
                        />
                        {errors.username && <span role="alert">{errors.username.message}</span>}
                      </li>
                      <li>
                        <input
                          type="password"
                          id="password"
                          aria-invalid={errors.password ? 'true' : 'false'}
                          {...register('password', {
                            required: 'Campo obrigatório.',
                          })}
                          disabled={loading}
                          placeholder="Password"
                        />
                        {errors.password && <span role="alert">{errors.password.message}</span>}
                      </li>
                    </ul>
                    <Link to={'/recovery'} className="text-[#2475FF] block text-right underline py-5">
                      Esqueci a senha
                    </Link>
                  </>
                )}
                {authTypes && authTypes.sso && <p className="text-white block mt-6 text-center">Single Sign-On (SSO)</p>}
                {!authTypes ||
                  (authTypes && !authTypes.auto && !authTypes.emailPassword && !authTypes.sso && (
                    <>
                      <ul>
                        <li>
                          <input
                            type="text"
                            id="username"
                            aria-invalid={errors.username ? 'true' : 'false'}
                            {...register('username', {
                              required: 'Campo obrigatório.',
                            })}
                            disabled={loading}
                            placeholder="Usuário"
                          />
                          {errors.username && <span role="alert">{errors.username.message}</span>}
                        </li>
                        <li>
                          <input
                            type="password"
                            id="password"
                            aria-invalid={errors.password ? 'true' : 'false'}
                            {...register('password', {
                              required: 'Campo obrigatório.',
                            })}
                            disabled={loading}
                            placeholder="Password"
                          />
                          {errors.password && <span role="alert">{errors.password.message}</span>}
                        </li>
                      </ul>
                      <Link to={'/recovery'} className="text-[#2475FF] block text-right underline py-5">
                        Esqueci a senha
                      </Link>
                    </>
                  ))}

                {authTypes && (authTypes.auto || authTypes.emailPassword) ? (
                  <button type="submit" disabled={loading}>
                    ACESSAR
                  </button>
                ) : (
                  <button type="submit" disabled={loading}>
                    ACESSAR
                  </button>
                )}

                {authTypes && authTypes.sso && (
                  <button type="button" onClick={loginWithSSO}>
                    ACESSAR
                  </button>
                )}

                {registerTypes && (registerTypes.emailPassword || registerTypes.customFields) && (
                  <Link to={'/register'} className="block mt-6 text-center cadastre">
                    Cadastre-se
                  </Link>
                )}

                <div className="text-center mt-4">
                  <small className="text-black">v.2.3</small>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

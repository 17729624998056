import { useAuth, useBasic } from 'contexts';
import { useCallback, useEffect, useState } from 'react';

import UserInfo from 'components/UserInfo';
import { AiOutlineTrophy } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './style.scss';

export function Header() {
  const authContext = useAuth();
  const { user } = authContext;

  return <header>{user ? <UserInfo /> : null}</header>;
}

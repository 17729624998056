import { useEffect, useMemo, useState } from 'react';
import { HiArrowLeft } from 'react-icons/hi';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAuth, useBasic } from 'contexts';
// import { gamesList, gamesNarrativeList } from '../Games';

import './style.scss';
import { CgCheck, CgChevronRight } from 'react-icons/cg';
import { getDetailContentGame } from 'services';

import placeholder from 'assets/images/games/placeholder.jpg';

export default function GamesListPage() {
  const { setShowHeader, appearance } = useBasic();
  const { gameID } = useParams();
  const [gameData, setGameData] = useState<any>();

  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  function handleClick(item: any) {
    navigate(`/games/${item.type.replace('game_', '')}/${item._id}`, {
      state: { previous: location },
    });
  }

  useEffect(() => {
    if (appearance?.navigation?.displayMenu) {
      setShowHeader(true);
    }

    return () => setShowHeader(true);
  }, [setShowHeader, appearance]);

  useEffect(() => {
    const loadData = async () => {
      if (!gameID || !user?._id) return;

      const data = await getDetailContentGame(gameID, true, true, user?._id);
      setGameData(data);
    };

    loadData();
  }, [gameID, user]);

  if (!gameID || !user) return null;

  return (
    <div id="games-detail" className="flex flex-col h-full gap-4">
      <div
        className={`head block lg:flex justify-between w-full ${
          !!gameData?.description?.length && !!gameData?.howToPlay?.length ? 'items-start' : 'items-center'
        }`}
      >
        {!appearance?.navigation || appearance?.navigation?.displayMenu ? (
          <Link to=".." className="back">
            <span className="ico">
              <HiArrowLeft />
            </span>
            Voltar
          </Link>
        ) : (
          <div />
        )}

        <div className={'crumbs has-btn-back text-right'}>
          {!!gameData && (
            <p>
              <strong>{gameData.title}</strong>
              {!!gameData.description?.length && !!gameData.howToPlay?.length && (
                <span className="title">{gameData.description}</span>
              )}
            </p>
          )}
        </div>
      </div>

      {/* <h3>List</h3> */}

      <div className="flex flex-col gap-8">
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
          {gameData?.map((game: any) => (
            <div
              key={game._id}
              onClick={e => handleClick(game)}
              className={`${
                game.completed > 0 ? 'completed-card' : ''
              } hover:ring-2 hover:cursor-pointer game-list-item flex flex-col lg:flex-row justify-center lg:justify-start items-center p-3 lg:p-0 shadow-xl rounded-xl overflow-hidden`}
            >
              <div className="mb-5 lg:mb-0 w-24 h-24 lg:w-32 lg:h-32 p-1 flex">
                <img
                  src={game.image || game.extra.image || placeholder}
                  alt={game.title}
                  className="rounded-md lg:rounded-lg object-cover w-full h-full bg-gray-100"
                />
              </div>
              <div className="space-y-2 px-5 lg:px-10 flex-1 text-center lg:text-left">
                <h4 className="text-base lg:text-xl">{game.title}</h4>
                <p className="text-gray-500 leading-tight">{game.extra.description}</p>
              </div>
              <div className="p-5 lg:p-8">
                <div className="border-gray-300 border rounded-full aspect-square p-2">
                  {game.completed > 0 ? <CgCheck size={24} /> : <CgChevronRight size={24} />}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <GameContainer id={gameID} /> */}

      {/* {(!!gameData?.howToPlay?.length || !!gameData?.description?.length) && (
        <div>
          <h5 className="font-bold">Como Jogar:</h5>
          <p>{gameData.howToPlay || gameData.description || ''}</p>
        </div>
      )} */}
    </div>
  );
}

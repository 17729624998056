import { createContext, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { PointsCategoriesInterface, getAppearance, getMessagesIDs, getNewsIDs, getPointsCategories } from 'services';
import { AppearanceInterface, ChildrenProp, MessagesIDsInterface, NewsIDsInterface, UserPointsInterface } from 'types';
import { useAuth } from './Auth.context';

export interface BasicContextInterface {
  appearance: AppearanceInterface | undefined;
  fullLoading: boolean;
  showHeader: boolean;
  showFooter: boolean;
  showSideNav: boolean;
  sideNavOpened: boolean;
  customClass: string;
  width: number;
  height: number;
  userPoints: UserPointsInterface[] | undefined;
  pointsCategories: PointsCategoriesInterface[] | undefined;
  newsIDs: NewsIDsInterface[] | undefined | null;
  messagesIDs: MessagesIDsInterface | undefined | null;
  loadingNewsIDs: boolean;
  loadingMessagesIDs: boolean;
  setLoadingNewsIDs: (value: boolean) => void;
  setLoadingMessagesIDs: (value: boolean) => void;
  setFullLoading: (value: boolean) => void;
  setShowHeader: (value: boolean) => void;
  setShowFooter: (value: boolean) => void;
  setShowSideNav: (value: boolean) => void;
  setSideNavOpened: (value: boolean) => void;
  setCustomClass: (value: string) => void;
}

const BasicContext = createContext({} as BasicContextInterface);

function BasicProviderData(): BasicContextInterface {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [fullLoading, setFullLoading] = useState<boolean>(true);
  const [showHeader, setShowHeader] = useState<boolean>(true);
  const [showFooter, setShowFooter] = useState<boolean>(false);
  const [showSideNav, setShowSideNav] = useState<boolean>(true);
  const [sideNavOpened, setSideNavOpened] = useState<boolean>(true);
  const [customClass, setCustomClass] = useState<string>('');
  const [appearance, setAppearance] = useState<AppearanceInterface | undefined>();
  const [width, height] = useWindowSize();

  const [pointsCategories, setPointsCategories] = useState<PointsCategoriesInterface[] | undefined>();
  const [newsIDs, setNewsIDs] = useState<NewsIDsInterface[] | undefined | null>();
  const [messagesIDs, setMessagesIDs] = useState<MessagesIDsInterface | undefined | null>();
  const [loadingNewsIDs, setLoadingNewsIDs] = useState<boolean>(false);
  const [loadingMessagesIDs, setLoadingMessagesIDs] = useState<boolean>(false);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }

      window.addEventListener('resize', updateSize);

      updateSize();

      return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
  }

  const userPoints: UserPointsInterface[] | undefined = useMemo(() => {
    return Object.keys(user?.point_categories || {}).length && pointsCategories && user
      ? Object.keys(user?.point_categories)
          .map(key => {
            const point = pointsCategories.find(item => item._id === key);

            return {
              ...point,
              total: user.point_categories[key],
            };
          })
          .filter(x => !!x)
      : undefined;
  }, [pointsCategories, user]);

  useEffect(() => {
    function loadNewsIDs() {
      setLoadingNewsIDs(true);

      getNewsIDs(user?._id)
        .then(data => setNewsIDs(data))
        .catch(() => setNewsIDs(null))
        .finally(() => setLoadingNewsIDs(false));
    }

    function loadMessagesIDs() {
      setLoadingMessagesIDs(true);

      getMessagesIDs(user?._id)
        .then(data => setMessagesIDs(data))
        .catch(() => setMessagesIDs(null))
        .finally(() => setLoadingMessagesIDs(false));
    }

    if (user?._id) {
      getPointsCategories().then(data => setPointsCategories(data));
      loadNewsIDs();
      loadMessagesIDs();
    }

    document.addEventListener('reload:newsids', loadNewsIDs, false);
    document.addEventListener('reload:messagesids', loadMessagesIDs, false);

    return () => {
      document.removeEventListener('reload:newsids', loadNewsIDs);
      document.removeEventListener('reload:messagesids', loadMessagesIDs);
    };
  }, [user]);

  useEffect(() => {
    getAppearance()
      .then(data => {
        if (data) {
          (document?.querySelector(':root') as HTMLElement)?.style.setProperty(
            '--bg-page',
            data.layout.bgPage?.length ? data.layout.bgPage : '#503e9d',
          );

          if (data.images && data.images.backgroundLogin) {
            const element = document.querySelector('.container.auth.no-aside.aside-min') as HTMLElement;
            if (element) {
              element.style.backgroundImage = `url("${data.images.backgroundLogin}")`;
            }
          }

          if (data.images.favicon?.length) {
            document.querySelectorAll('link[rel="icon"]').forEach(tag => {
              tag.setAttribute('href', data.images.favicon);
            });
          }

          if (data.title?.length) {
            document.querySelectorAll('meta[property="og:title"]').forEach(tag => {
              tag.setAttribute('content', data.title);
            });

            document.title = data.title;
          }

          if (data.description?.length) {
            document.querySelectorAll('meta[name="description"], meta[property="og:description"]').forEach(tag => {
              tag.setAttribute('content', data.description);
            });
          }
        }

        setAppearance(data);

        if (data?.navigation) {
          if (data?.navigation?.initialUrl) {
            navigate(data.navigation?.initialUrl);
          }
          setShowSideNav(data?.navigation?.displayMenu);
        }
      })
      .finally(() =>
        setTimeout(() => {
          setFullLoading(false);
        }, 200),
      );
  }, [navigate]);

  return {
    appearance,
    fullLoading,
    showHeader,
    showFooter,
    showSideNav,
    sideNavOpened,
    customClass,
    width,
    height,
    userPoints,
    pointsCategories,
    newsIDs,
    messagesIDs,
    loadingNewsIDs,
    loadingMessagesIDs,
    setLoadingNewsIDs,
    setLoadingMessagesIDs,
    setFullLoading,
    setShowHeader,
    setShowFooter,
    setShowSideNav,
    setSideNavOpened,
    setCustomClass,
  };
}

const BasicProvider = ({ children }: ChildrenProp) => {
  const contextData: BasicContextInterface = BasicProviderData();
  return <BasicContext.Provider value={contextData}>{children}</BasicContext.Provider>;
};

const useBasic = (): BasicContextInterface => {
  const context = useContext(BasicContext);

  if (typeof context === 'undefined') {
    throw new Error('useBasic must be used within an BasicProvider');
  }

  return context;
};

export { BasicProvider, useBasic };
